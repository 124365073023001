import React, { useContext, useEffect, useState } from "react";

import api from "../api/axios";
import {
  cartValue,
  checkOutValue,
  deliveryAddressInfovalue,
  ipnvalue,
  paymentStatus,
  pickupTimeValue,
  savethisAddress,
} from "../global/initialValueFromLocalStorage";

const OurGjcContext = React.createContext();

export const useAppContext = () => {
  return useContext(OurGjcContext);
};

const GjcProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(cartValue); // cart items from localStorage
  const [totalItemQtyinCart, setTotalItemQtyinCart] = useState(0);
  const [checkoutCartItems, setcheckoutCartItems] = useState(checkOutValue); // checkout items from localStorage
  const [SubTotal, setSubTotal] = useState(0);
  const [ipn, setIpn] = useState(ipnvalue);
  const [payment, setPayment] = useState(paymentStatus);
  const [deliveryAddressInfo, setDeliveryAddressInfo] = useState(
    deliveryAddressInfovalue
  );
  const [saveThisAddress, setSaveThisAddress] = useState(savethisAddress);
  const [promotions, setPromotions] = useState();
  const [pickupTime, setPickupTime] = useState(pickupTimeValue);

  useEffect(() => {
    console.log("updated Picup times", pickupTime);
  }, [pickupTime]);

  useEffect(() => {
    // console.log("loggedin:", loggedinStatus);
    if (loggedinStatus == null) {
      setisLoggedIn(false);
      localStorage.setItem("isLoggedIn", false);
    } else {
      // setisLoggedIn(loggedinStatus)
    }
  }, [loggedinStatus]);

  useEffect(() => {
    // JSON values have to be parsed when set and Stringify when get
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    localStorage.setItem("pickupTimeValue", JSON.stringify(pickupTime));
    localStorage.setItem("savethisAddress", JSON.stringify(saveThisAddress));

    localStorage.setItem(
      "deliveryAddressInfo",
      JSON.stringify(deliveryAddressInfo)
    );
    localStorage.setItem("ipnvalue", JSON.stringify(ipn));
    localStorage.setItem("paymentStatus", JSON.stringify(payment));
    localStorage.setItem(
      "checkoutCartItems",
      JSON.stringify(checkoutCartItems)
    );
    // console.log("deliveryAddressInfo:", deliveryAddressInfo);
  }, [cartItems, checkoutCartItems, ipn, deliveryAddressInfo]);

  useEffect(() => {
    let total = 0;
    cartItems.map((item) => {
      item.variation.map((variation) => {
        total += variation.qty;
      });
    });
    setTotalItemQtyinCart(total);
    localStorage.setItem("totalItemQtyinCart", JSON.stringify(total));
    // console.log("Cart Items:", total);
  }, [cartItems]);

  // console.log("Initial state (isLoggedIn):", isLoggedIn);

  // value holds the loggedIn status and save in localstorage
  // const loggedinStatus = JSON.parse(localStorage.getItem("isLoggedIn")); // TODO:
  var loggedinStatus; // TODO:
  if (!localStorage["isLoggedIn"]) {
    loggedinStatus = null;
  } else if (localStorage["isLoggedIn"] == "undefined") {
    loggedinStatus = null;
  } else {
    loggedinStatus = JSON.parse(localStorage.getItem("isLoggedIn"));
  }

  // userValue holds the loggedIn user data and save in localstorage
  // previes one
  // const userValue = JSON.parse(localStorage.getItem("userData"));
  // new to handle undefined
  const userDataString = localStorage.getItem("userData");
  const userValue =
    userDataString !== "undefined" ? JSON.parse(userDataString) : {};

  // Function to set login status
  const [isLoggedIn, setisLoggedIn] = useState(loggedinStatus);
  // Function to set current User
  const [currentUser, setCurrentUser] = useState(userValue);

  const [addressId, setAddressId] = useState("");

  const fetchUser = async () => {
    try {
      const token = await localStorage.getItem("token");
      // console.log("naluierwgfvelkjfnoiu:", typeof token);

      // if (token !== null) {
      //   setisLoggedIn(true);
      // } else {
      //   setisLoggedIn(false);
      // }

      // console.log("token from first async storage", token);
      // console.log("loggedin from context", isLoggedIn);
      // console.log(
      //   "Loggedin from Localstorage",
      //   localStorage.getItem("isLoggedIn")
      // );
    } catch (e) {
      // setisLoggedIn(false);
    }
  };

  useEffect(() => {
    // fetchUser();
  }, [isLoggedIn]);

  // if (selectedOutletFromLocalstorage) {
  //   selectedOutletFromLocalstorage = JSON.parse(
  //     localStorage.getItem("selectedOutlet")
  //   );
  // }

  // Variable to hold all outlets information from localstorage
  // if no outlet found or undefined, set outlet as null

  // State to hold outlets information

  var singleOutletvalue;

  if (!localStorage["singleOutlet"]) {
    singleOutletvalue = orderType == null;
  } else if (localStorage["singleOutlet"] == "undefined") {
    singleOutletvalue = null;
  } else if (localStorage["singleOutlet"] == undefined) {
    singleOutletvalue = null;
  } else {
    singleOutletvalue = JSON.parse(localStorage.getItem("singleOutlet"));
  }

  /// State to hold selected outlet
  const [singleOutlet, setSingleOutlet] = useState(singleOutletvalue);

  var singleOutletID;

  if (!localStorage["outletID"]) {
    singleOutletID = null;
  } else if (localStorage["outletID"] == "undefined") {
    singleOutletID = null;
  } else {
    singleOutletID = JSON.parse(localStorage.getItem("outletID"));
  }
  const [outletID, setOutletID] = useState(singleOutletID);

  // Realtime outlet data getting from api

  // Outlets start

  // Variable to hold selected outlet from localstorage

  // const selectedOutletFromLocalstorage = JSON.parse(
  //   localStorage["selectedOutlet"] ==
  //   "undefined" ? null : localStorage.getItem("selectedOutlet"));

  const selectedOutletFromLocalstorage = JSON.parse(
    localStorage.getItem("selectedOutlet")
      ? localStorage.getItem("selectedOutlet")
      : 0
  );
  // if (selectedOutletFromLocalstorage) {
  // selectedOutletFromLocalstorage = JSON.parse(
  //     localStorage.getItem("selectedOutlet")
  // );
  // }

  // Variable to hold all outlets information from localstorage
  // if no outlet found or undefined, set outlet as null
  let allOutletsDataFromLocalstorage;

  if (!localStorage["setOutlets"]) {
    allOutletsDataFromLocalstorage = null;
  } else if (localStorage["setOutlets"] == "undefined") {
    allOutletsDataFromLocalstorage = null;
  } else {
    allOutletsDataFromLocalstorage = JSON.parse(
      localStorage.getItem("setOutlets")
    );
  }

  // State to hold outlets information
  const [outlets, setOutlets] = useState();

  // State to hold selected outlet information
  const [selectedOutlet, setSelectedOutlet] = useState(
    selectedOutletFromLocalstorage
  );

  // Realtime outlet data getting from api
  useEffect(() => {
    const getOutlets = async () => {
      try {
        const { data } = await api.get("/outlet");
        // data in an Array with all outlets
        // loading data into "outlets" state
        setOutlets(data);

        // Saving all outlets data in localstorage
        localStorage.setItem("setOutlets", JSON.stringify(data));
        // Setting initial outlet

        setSingleOutlet(data[0]);
        setSelectedOutlet(0);
        setOutletID(data[0]._id);
        localStorage.setItem("singleOutlet", JSON.stringify(data[0]));
        localStorage.setItem("outletID", JSON.stringify(data[0]._id));
        localStorage.setItem("selectedOutlet", JSON.stringify(0));
      } catch (error) {
        console.log("Axios Error", error);
      }
    };

    // If outlet data in localstorage is empty, run getOutlets function
    if (!allOutletsDataFromLocalstorage) {
      getOutlets();
    } else {
      // if outlet data is already present in localstorage, get the outlet data and save them in outlets
      setOutlets(allOutletsDataFromLocalstorage);
    }
    // Dependency on all outlet data call, preventing api call every time
  }, []);

  // Outlets ends
  // Dependency on all outlet data call, preventing api call every time

  /*************************** Outlet dropdown starts ***********************/

  //  select outlet onChange
  const outletChangeHandler = (e) => {
    let i = parseInt(e);
    // console.log(i);
    // console.log("onchange event::", outlets[i].name);
    setSingleOutlet(outlets[i]);
    setSelectedOutlet(i);
    setOutletID(outlets[i]._id);
    localStorage.setItem("singleOutlet", JSON.stringify(outlets[i]));
    localStorage.setItem("outletID", JSON.stringify(outlets[i]._id));
    // console.log("Outlet input select value::", e.target.value);
    localStorage.setItem("selectedOutlet", JSON.stringify(i));
  };

  // To see realtime change of outlet selection

  /*************************** Outlet dropdown starts ***********************/

  // Variable to hold selected Order Type from localstorage
  const selectedOrderTypeFromLocalstorage = JSON.parse(
    localStorage.getItem("orderType")
  );

  // State to hold selected Order Type information
  const [orderType, setOrderType] = useState(
    selectedOrderTypeFromLocalstorage
      ? selectedOrderTypeFromLocalstorage
      : "pickup"
  );

  //  select order type onChange
  const orderTypeChangeHandler = (orderType) => {
    setOrderType(orderType);
    orderType === "delivery"
      ? setSingleOutlet(deliveryLocations[selectedDeliveryLocation])
      : setSingleOutlet(outlets[selectedOutlet]);
    let outlet =
      orderType === "delivery"
        ? deliveryLocations[selectedDeliveryLocation]
        : outlets[selectedOutlet];
    localStorage.setItem("orderType", JSON.stringify(orderType));
    localStorage.setItem("singleOutlet", JSON.stringify(outlet));
  };

  // To see realtime change of Order Type selection
  useEffect(() => {
    localStorage.setItem("orderType", JSON.stringify(orderType));
    // console.log("Current order type:", orderType);
    // console.log("Selected Order Type from localstorage:", localStorage.getItem("selectedOutlet"));
  }, [orderType]);

  /*************************** Outlet dropdown ends ***********************/

  /*************************** Promotions start ****************************/

  const getPromotions = async () => {
    try {
      const response = await api.get("/promotion");
      setPromotions(response.data);
    } catch (error) {
      console.log("Axios Error", error);
    }
  };

  useEffect(() => {
    getPromotions();
  }, []);
  /*************************** Promotions End ****************************/

  /*************************** Delivery location dropdown ***********************/

  const selectedDeliveryLocationFromLocalstorage = JSON.parse(
    localStorage.getItem("selectedDeliveryLocation")
      ? localStorage.getItem("selectedDeliveryLocation")
      : 0
  );

  // Variable to hold all outlets information from localstorage
  // if no outlet found or undefined, set outlet as null
  var allDeliveryLocationsDataFromLocalstorage;

  if (!localStorage["setDeliveryLocations"]) {
    allDeliveryLocationsDataFromLocalstorage = null;
  } else if (localStorage["setDeliveryLocations"] == "undefined") {
    allDeliveryLocationsDataFromLocalstorage = null;
  } else {
    allDeliveryLocationsDataFromLocalstorage = JSON.parse(
      localStorage.getItem("setDeliveryLocations")
    );
  }

  // const allDeliveryLocationsDataFromLocalstorage = JSON.parse(
  //   typeof localStorage["setDeliveryLocations"] == "undefined"
  //     ? null
  //     : localStorage.getItem("setDeliveryLocations")
  // );

  // State to hold outlets information
  const [deliveryLocations, setDeliveryLocations] = useState();

  // State to hold selected outlet information
  const [selectedDeliveryLocation, setSelectedDeliveryLocation] = useState(
    selectedDeliveryLocationFromLocalstorage
  );

  useEffect(() => {
    const getDeliveryLocations = async () => {
      try {
        const { data } = await api.get("/delivery-locations");
        // Data returning array of locations
        setDeliveryLocations(data);
        localStorage.setItem("setDeliveryLocations", JSON.stringify(data));
        // Setting initial delivery location
        setOutletID(data[0].outlet._id);
        // console.log("Delivery Locations:", data);
        // console.log("###Delivery Locations API Call###");

        //commentouted for pickup only, when open delivery then need to fix
        setSingleOutlet(data[0]);
        setSelectedOutlet(0);
        localStorage.setItem("singleOutlet", JSON.stringify(data[0]));
        localStorage.setItem("outletID", JSON.stringify(data[0]._id));
        localStorage.setItem("selectedOutlet", JSON.stringify(0));
      } catch (error) {
        console.log("Axios Error", error);
      }
    };

    // If outlet data in localstorage is empty, run getOutlets function
    if (!allDeliveryLocationsDataFromLocalstorage) {
      getDeliveryLocations();
    } else {
      // if outlet data is already present in localstorage, get the outlet data and save them in outlets
      setDeliveryLocations(allDeliveryLocationsDataFromLocalstorage);
    }
    // Dependency on all outlet data call, preventing api call every time
  }, []);

  //  select outlet onChange
  const deliveryLocationChangeHandler = (e) => {
    let i = parseInt(e);
    // console.log("what in the I?", i);
    // console.log("Locations are?", deliveryLocations);
    setSingleOutlet(deliveryLocations[i]);
    setOutletID(deliveryLocations[i].outletID);
    // setOutletID(deliveryLocations[i].outlet._id);
    localStorage.setItem("singleOutlet", JSON.stringify(deliveryLocations[i]));
    localStorage.setItem(
      "outletID",
      // JSON.stringify(deliveryLocations[i].outlet._id)
      JSON.stringify(deliveryLocations[i].outletID)
    );
    // console.log("onchange event type::", typeof parseInt(e.target.value));

    setSelectedDeliveryLocation(i);
    // console.log(
    //   "input select value delivary location:",
    //   deliveryLocations[i]._id
    // );
    localStorage.setItem("selectedDeliveryLocation", JSON.stringify(i));
  };

  /*************************** Delivery location dropdown ***********************/

  /*----------------------- Menu Start -----------------*/
  // Declaring state for Menu Data
  const [menuData, setMenuData] = useState();

  /*-------------------- Menu End ----------------*/

  return (
    <OurGjcContext.Provider
      value={{
        setMenuData,
        menuData,
        pickupTime,
        setPickupTime,
        cartItems,
        setCartItems,
        checkoutCartItems,
        setcheckoutCartItems,
        SubTotal,
        setSubTotal,
        ipn,
        setIpn,
        payment,
        setPayment,
        isLoggedIn,
        setisLoggedIn,
        currentUser,
        setCurrentUser,
        addressId,
        setAddressId,
        outlets,
        selectedOutlet,
        setSelectedOutlet,
        outletChangeHandler,
        singleOutlet,
        setSingleOutlet,
        orderType,
        setOrderType,
        orderTypeChangeHandler,
        deliveryLocations,
        setDeliveryLocations,
        selectedDeliveryLocation,
        setSelectedDeliveryLocation,
        deliveryLocationChangeHandler,
        outletID,
        deliveryAddressInfo,
        setDeliveryAddressInfo,
        saveThisAddress,
        setSaveThisAddress,
        totalItemQtyinCart,
        promotions,
      }}
    >
      {children}
    </OurGjcContext.Provider>
  );
};

export { OurGjcContext, GjcProvider };

import React, { Fragment, useEffect } from "react";
import Loader from "../layouts/Loader";

const ItemDetails = ({ item, setSelectedItem, setIsVariationSelected }) => {
  useEffect(() => {
    var isVaraiationSelected = item.variation.find((v) => v.qty > 0);

    if (isVaraiationSelected) {
      setIsVariationSelected(true);
    } else {
      setIsVariationSelected(false);
    }
  }, [item]);

  //Function for Counter Minus
  function incrementQty(selectItem, varID) {
    let variation = selectItem.variation;
    let i = variation.findIndex((variation) => variation._id === varID);

    if (variation[i].qty) {
      variation[i].qty += 1;
    } else {
      variation[i].qty = 1;
    }

    setSelectedItem({
      ...item,
      variation: variation,
    });
  }

  //Function for Counter Minus
  function decrementQty(selectItem, varID) {
    let variation = selectItem.variation;
    let i = variation.findIndex((variation) => variation._id === varID);

    if (variation[i].qty) {
      variation[i].qty > 0 ? (variation[i].qty -= 1) : (variation[i].qty = 0);
    } else {
      variation[i].qty = 0;
    }

    setSelectedItem({
      ...item,
      variation: variation,
    });
  }

  //Function for Checkbox check
  function setQuantyOne(selectItem, varID) {
    let i = selectItem.variation.findIndex(
      (variation) => variation._id === varID
    );

    let variation = selectItem.variation;

    variation[i].qty = 1;

    setSelectedItem({
      ...item,
      variation: variation,
    });
  }

  //Function for Checkbox uncheck
  function setQuantyZero(selectItem, varID) {
    let i = selectItem.variation.findIndex(
      (variation) => variation._id === varID
    );

    let variation = selectItem.variation;

    variation[i].qty = 0;

    setSelectedItem({
      ...item,
      variation: variation,
    });
  }

  return (
    <>
      {!item ? (
        <Loader />
      ) : (
        <section className="item-details" aria-labelledby="t1">
          <h2 className="modal-heading" id="t1">
            Item Details
          </h2>
          <h3 className="item-name">
            {item.name} {item.remark ? <span>({item.remark})</span> : ""}
          </h3>
          {item.details ? <p>{item.details}</p> : ""}

          <div className="item-table">
            {item.variation &&
              item.variation.map((variation, index) => {
                return (
                  <Fragment key={index}>
                    <div className="start">
                      <input
                        type="checkbox"
                        className="check"
                        checked={variation.qty > 0 ? true : false}
                        onClick={() => {
                          if (variation.qty > 0) {
                            setQuantyZero(item, variation._id);
                          } else {
                            setQuantyOne(item, variation._id);
                          }
                        }}
                      />
                      <h4 className="price-label">{variation.name}</h4>
                    </div>
                    <div className="middle">
                      <h5 className="item-price">Tk. {variation.price}</h5>
                    </div>
                    <div className="end">
                      <button
                        className="minus"
                        onClick={() => {
                          decrementQty(item, variation._id);
                        }}>
                        -
                      </button>
                      <input
                        type="text"
                        className="quantity"
                        value={variation.qty ? variation.qty : 0}></input>
                      <button
                        className="plus"
                        onClick={() => {
                          incrementQty(item, variation._id);
                        }}>
                        +
                      </button>
                    </div>
                  </Fragment>
                );
              })}
          </div>
        </section>
      )}
    </>
  );
};

export default ItemDetails;

// Notification.js
import React, { useEffect, useState } from "react";
import addNotification from "react-push-notification";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import CancelOrder from "../orders/CancelOrder";
import Modal from "../layouts/Modal";

const AllNotification = () => {
  // const socket = io.connect(
  //   // "http://localhost:5000/"
  //   // "https://gloriajeanscoffeesbd.com/"
  //   "https://dev.gloriajeanscoffeesbd.com/socket.io/",

  //   {
  //     path: "/socket.io/",
  //     withCredentials: true,
  //     transport: ["websocket", "polling"],
  //     // transports: ["websocket", "xhr-polling"],
  //   }
  // );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderId, setOrderId] = useState(null);

  console.log(orderId);

  const showNotification = (data) => {
    let message = "";
    let type = "";

    switch (data.orderStatus) {
      case "Cancelled":
        message = "Your order has been cancelled";
        type = "error";
        break;
      case "Preparing":
        message = "Your order has been accepted and is being prepared.";
        type = "info";
        break;
      case "Ready":
        message = "Your order is ready!";
        type = "success";
        break;
      case "Completed":
        message = "Your order has been completed. Enjoy!";
        type = "success";
        break;
      default:
        message = "Unexpected order status or unknown status received.";
        type = "warn";
        break;
    }

    toast(message, {
      type: type,
      theme: "dark",
    });
    data.orderStatus === "Cancelled" && setIsModalOpen(true);

    // Send push notification using react-push-notification
    addNotification({
      title: "Order Status Changed",
      subtitle: "Check the latest update on your order",
      message: message,
      theme: "darkblue",
      native: true, // when using native.
    });
  };

  // useEffect(() => {
  //   socket.on("orderStatusChange", (data) => {
  //     showNotification(data);
  //     setOrderId(data.orderId);
  //   });

  //   return () => {
  //     // socket.off("orderStatusChange");
  //     socket.disconnect();
  //   };
  // }, [socket]);

  return (
    <div>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <CancelOrder
          isOpen={isModalOpen}
          orderId={orderId}
          onClose={() => setIsModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default AllNotification;

import { motion } from "framer-motion";
import { useAnimation } from "framer-motion";
/** @format */

import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { animationContainer, fadeInDown } from "../../animations/variants";
import api from "../../api/axios";
import img from "../../call.svg";
import countries from "../../data/countries.json";
import { useAppContext } from "../../global/gjcContext";
import Search from "../menu/Search";
import EcommerceHeader from "./EcommerceHeader";

import "./EcommerceHeader.css";

const HeaderTwo = () => {
  const [toggleButton, setToggleButton] = useState(false);

  // global state declaration
  const { totalItemQtyinCart, isLoggedIn } = useAppContext();

  // Scroll to Top
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = 120;
    window.scrollTo({
      top: yCoordinate + yOffset,
      behavior: "smooth",
    });
  };

  // Expand Mobile Menu with Toggle button click

  const toggleMobileMenu = () => {
    setToggleButton(!toggleButton);
  };

  let menuRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        toggleButton && setToggleButton(false);
      }
    });
  });

  // Check if Mobile Device

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 1024;

  // Code for Animation of Mobile menu

  const controls = useAnimation();

  const [ref, inView] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else if (!inView) {
      controls.start("hidden");
    }
  }, [controls, inView]);

  // const alert = useAlert();
  // const dispatch = useDispatch();

  // const { cartItems } = useSelector((state) => state.cart);

  // const { user, loading } = useSelector((state) => state.user);
  // const logoutHanlder = () => {
  // dispatch(logout());
  // alert.success('Logged out successfully!');
  // };

  // console.log("Cart Items:", totalItemQtyinCart);

  const [countriesExpand, setCountriesExpand] = useState(false);

  let countryRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (countryRef.current && !countryRef.current.contains(event.target)) {
        countriesExpand && setCountriesExpand(false);
      }
    });
  });

  return (
    <>
      <nav
        className="container navbar navbar-expand-lg navbar-light "
        ref={menuRef}
      >
        <div className="ecomHeader content">
          <EcommerceHeader countries={countries} />
        </div>
        <div className="lower-nav content">
          <Link className="logo" to="/">
            <img
              src="/Assets/img/home/gjc_officialgjc_2.svg"
              alt="Gloria Jean's Coffees Oficial Logo"
              srcSet=""
              className="main-logo"
            />
          </Link>

          <div
            className={
              toggleButton ? "navbar-collapse" : "collapse navbar-collapse"
            }
            id="navbarCollapse"
            ref={ref}
            animate={controls}
            initial={isMobile ? "hidden" : "visible"}
            variants={animationContainer}
          >
            <ul className="navbar-nav">
              <li>
                <Link
                  to="/about"
                  className="nav-item nav-link"
                  onClick={toggleMobileMenu}
                >
                  About
                </Link>
              </li>

              <li>
                <Link
                  to="/promotions"
                  className="nav-item nav-link"
                  onClick={toggleMobileMenu}
                >
                  Promotions
                </Link>
              </li>

              <li>
                <Link
                  to="/menu"
                  className="nav-item nav-link"
                  onClick={toggleMobileMenu}
                >
                  Menu
                </Link>
              </li>

              <li>
                <HashLink
                  smooth
                  to="/#find-outlet"
                  className=" nav-location"
                  scroll={(el) => scrollWithOffset(el)}
                  onClick={toggleMobileMenu}
                >
                  {/* <img src="/Assets/img/home/location.svg" width="30" alt=""/> */}
                  Outlets
                </HashLink>
              </li>

              {/* <li variants={
                                isMobile && fadeInDown
                            }>
                                <Link to="/" className="nav-item nav-link"
                                    onClick={toggleMobileMenu}>
                                    Loyalty Program
                                </Link>
                            </li> */}
              <li>
                <Link
                  to="/orders"
                  className="nav-item nav-link"
                  onClick={toggleMobileMenu}
                >
                  Orders
                </Link>
              </li>
            </ul>
          </div>

          {/* <div className="rightPart" ref={countryRef}> */}
          {/* <input type="text" placeholder="Search food/drinks" /> */}
          {/* <Search /> */}
          {/* Flags */}

          {/* <a className="nav-call" href="tel:+880929333999">
              <img src={img} />
              <li>(+880) 1929 333 999</li>
            </a> */}

          {/* <div className="countries">
              <button
                className="countriesButton"
                onClick={() => {
                  setCountriesExpand((expand) => !expand);
                }}
              >
                <img src="/Assets/img/countries/Bangladesh.png" alt="BD" />
                BD
              </button>
              <div
                className={
                  countriesExpand
                    ? "countriesContainer container expand"
                    : "countriesContainer container"
                }
              >
                <div className="countryFlags">
                  {countries.map((flags, i) => (
                    <div key={"flag" + i}>
                      <img src={flags.img} alt={flags.name} />
                      <a target="_blank" href={flags.website} title="">
                        {flags.name}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}
          {/* Flags */}
          {/* </div> */}

          {/**************  Profile and Cart **************/}

          <div className="nav-social">
            {isLoggedIn ? (
              <>
                <Link to="/profile" className="profileManager icon">
                  <img src="/images/Profile.svg" alt="Profile" />
                </Link>
              </>
            ) : (
              <>
                <Link to="/login" className="profileManager icon">
                  <img src="/images/Profile.svg" alt="Profile" />
                </Link>
              </>
            )}
            <Link to="/cart" className="cartify icon">
              <img src="/images/CartIcon.svg" alt="Cart" />
              {totalItemQtyinCart && totalItemQtyinCart >= 0 ? (
                <>
                  <h4 className="cartItemQuantity">{totalItemQtyinCart}</h4>
                </>
              ) : (
                <></>
              )}
            </Link>
          </div>
          {/* <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
          <div className="end">
            <Link to="/menu" className="cta">
              Menu
            </Link>
            <div
              className={
                toggleButton ? "hamburger-btn toggle" : "hamburger-btn"
              }
              onClick={toggleMobileMenu}
            >
              <div className="line1"></div>
              <div className="line2"></div>
              <div className="line3"></div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default HeaderTwo;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import api from "../../api/axios";
import { useAppContext } from "../../global/gjcContext";
import { subTotalCountIncludeAddons } from "../../utilities/cart/subtotal-count";
import { totalItemCountFunc } from "../../utilities/checkout/chekout-total-count";

import "./payment.css";


const SuccessPayment = () => {
  const {
    ipn,
    setIpn,
    payment,
    setPayment,
    setCartItems,
    checkoutCartItems,
    setcheckoutCartItems,
    currentUser,
    orderType,
    singleOutlet,
    deliveryAddressInfo,
    setDeliveryAddressInfo,
    setSaveThisAddress,
    pickupTime,
  } = useAppContext();
  const [countLoad, setCountLoad] = useState(0);
  const [data, setData] = useState("");
  const [posResponse, setPosResponse] = useState(null);
  const navigate = useNavigate();

  // Getting payment method state
  const location = useLocation();
  const paymentMethod = location.state
    ? location.state.paymentMethod
    : "digital";

  const createProduct = () => {
    if (ipn !== "") {
      paymentMethod === "digital" ? ipnRequest() : createOrder(); /// TODO: Make conditional. Call ipnRequest() function only for Digital not for Cash
      setPayment((prev) => prev + 1);
      // setIpn("");
    } else{
      paymentMethod === 'cash' && createOrder();
    }
   
  };
  useEffect(() => {
    if (countLoad < 1) {
      setCountLoad(countLoad + 1);
    }
    if (countLoad === 0) {
      if (payment === 0) {
        // console.log("loaded create product");
        createProduct();
      } else {
        // console.log("payment done ");
        //navigate.push("/menu"); /// TODO: redirect to order details page
      }
    }
  }, [ipn, countLoad, payment, singleOutlet, currentUser]);

  const testPlacePOSOrder = async () => {
    try {
      // Sample data similar to your Postman request
      const posOrderData = {
        entities: [],
        orders: [
          {
            hosttOrderId: 1754482,
            departmentId: 7,
            menuItem: {
              menuItemId: 2416,
              name: "Water - 330ml",
              groupCode: "GJC Beverage",
            },
            menuItemPortion: {
              menuItemPortionId: 3050,
            },
            qty: 1.0,
            taxTemplates: [],
            orderTags: [],
            automationCommandIds: [],
            orderStates: ["Status=Submitted", "SStatus=Sent To Kitchen"],
            isFromHostt: true,
            taggedOrder: false,
          },
        ],
        ticketId: "00000013-0000-0000-0000-000000000000",
        automationCommands: "",
        hosttTicketId: "",
        sentBy: "GJC3 Manager",
        ticketNote: "welcome",
        noOfGuests: 2,
        isFromHostt: false,
        isSent: false,
        departmentId: 7,
        ticketTypeId: 7,
      };

      // Sending request to POS Backend
      const response = await axios.post(
        "http://103.199.109.226:96/api/Tickets/",
        posOrderData,
        {
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );

      console.log("RESPONSE FROM POS", response.data);
      return response.data;
    } catch (error) {
      console.log("POS ORDER FAILED!!!!!!!!!!!!! ::", error);
      throw error;
    }
  };

  const placePOSOrder = () => {
    let posOrderData = {
      entities: [],
      orders: [],
      automationCommands: "",
      hosttTicketId: "",
      sentBy: "GJC3 Manager",
      ticketNote: "",
      noOfGuests: 1,
      isFromHostt: false,
      isSent: true,
      departmentId: 0,
      ticketTypeId: 0,
    };

    if (orderType === "delivery") {
      posOrderData.entities.push(singleOutlet.outlet.posTableEntity);
      posOrderData.entities.push(singleOutlet.outlet.posServerEntity);
      posOrderData.departmentId = singleOutlet.outlet.posDepartmentId;
      posOrderData.ticketTypeId = singleOutlet.outlet.posTicketTypeId;
    } else {
      posOrderData.entities.push(singleOutlet.posTableEntity);
      posOrderData.entities.push(singleOutlet.posServerEntity);
      posOrderData.departmentId = singleOutlet.posDepartmentId;
      posOrderData.ticketTypeId = singleOutlet.posTicketTypeId;
    }

    checkoutCartItems &&
      checkoutCartItems.forEach((item) => {
        item.variation.forEach((variation) => {
          let orderSingle = {
            orderId: 1,
            menuItem: variation.posItemId,
            taxTemplates: "NA",
            qty: variation.qty,
            menuItemPortion: "Normal",
            orderTags: [],
            departmentId: posOrderData.departmentId,
            automationCommandIds: [],
            addedAutomationCommandIds: [],
            isFromHostt: false,
          };
          posOrderData.orders.push(orderSingle);
        });
      });

    console.log("POS ORDER DATA :::::", posOrderData);

    // Sending request to POS Backend
    try {
      let response = axios.post(
        "http://103.199.109.226:96/api/Tickets/",
        posOrderData,
        {
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      console.log("RESPONSE FROM POS", response);
      setPosResponse(response);
    } catch (error) {
      console.log("POS ORDER FAILED!!!!!!!!!!!!! ::", error);
      throw error;
    }

    // fetch("http://103.199.109.226:96/api/Tickets/", {
    //   method: "POST",
    //   body: JSON.stringify(posOrderData),
    //   headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     "Content-type": "application/json; charset=UTF-8",
    //   },
    // }).then(function (response) {
    //   return response.json();
    // });
  };

  const ipnRequest = async () => {
    // console.log("check order id before cancel", ipn);
    try {
      const valPayment = await api.post(`/validate/${ipn}`);

      console.log("after ipn", valPayment.data.status);
      if (valPayment.data.status === "INVALID_TRANSACTION") {
        // console.log("process order");

        // Place order in POS
        // let posResponse = await placePOSOrder().catch((e) => {
        //   return e;
        // });
        // console.log("RESPONSE FROM POS", posResponse);

        createOrder();
      } else {
        // console.log("do not process order");
      }
    } catch (error) {
      // console.log("Payment validation error", error);
    }
  };

  const createOrder = async () => {

    // console.log("cash delivery>>>>>>");

    let quantity = totalItemCountFunc(checkoutCartItems);
    const subtotal = subTotalCountIncludeAddons(checkoutCartItems);

    // finally create the order
    const { data } = await api.post("/user/order", {
      items: checkoutCartItems,
      orderType: orderType,
      outlet:
        orderType === "delivery" ? singleOutlet.outlet._id : singleOutlet._id,
      qty: quantity,
      total: subtotal,
      user: currentUser,
      paymentMethod: paymentMethod,
      paymentStatus: paymentMethod == "digital" ? "Successful" : "Pending",
      pickupTimes: pickupTime,
      deliveryAddress: orderType === "delivery" ? deliveryAddressInfo : {},
    });
    // console.log("order created");
    setData(data);
    // console.log("pickupTime==>>", pickupTime);
    // console.log(data);

    if (data.orderStatus === "Pending") {
      setTimeout(() => {
        setSaveThisAddress(false);
        setCartItems([]);
        setcheckoutCartItems([]);
        setDeliveryAddressInfo({
          house: "",
          road: "",
          sector: "",
          direction: "",
          area: "",
        });

        navigate("/order-detail/" + data._id, {
          orderDetails: data,
        });
      }, 1500);
    }
  };
  //  console.log("after creating the order", data);
  return (
    <div className="payment container">
      {paymentMethod === "digital" ? (
        <h1>
          Payment successful <span style={{ color: "#08c22d" }}> ✔ </span>
        </h1>
      ) : (
        ""
      )}

      <h1>{data === "" ? "Placing your Order 🛒" : "Your order is Placed!"}</h1>
    </div>
  );
};

export default SuccessPayment;

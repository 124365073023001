import React from "react";
import OfferCard from "../cards/offer-card";

import { motion } from "framer-motion";
import { animationContainer } from "../../../animations/variants";
import useAnimateOnInView from "../../../animations/useAnimationInView";
import { useAppContext } from "../../../global/gjcContext";

const OfferSection = () => {
  const { promotions } = useAppContext();

  const offerAnimation = useAnimateOnInView();
  const fadeInUpSlow = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
      },
    },
  };
  return (
    <div className="container">
      <div className="offer-wrapper">
        <motion.div
          ref={offerAnimation.ref}
          animate={promotions && promotions.length > 0 && "visible"}
          initial="hidden"
          variants={animationContainer}
          className="hero-offer"
        >
          {promotions &&
            promotions.length > 0 &&
            promotions.map((promotion, i) => {
              return (
                i < 4 && (
                  <motion.a
                    href="/promotions"
                    variants={fadeInUpSlow}
                    className="offer-card"
                  >
                    <OfferCard imgSrc={promotion.imgURL}>
                      {promotion.name}
                    </OfferCard>
                  </motion.a>
                )
              );
            })}
        </motion.div>
      </div>
    </div>
  );
};

export default OfferSection;

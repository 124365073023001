import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import io from "socket.io-client";

import api from "../../api/axios";
import Loader from "../layouts/Loader";
import Accepted from "./order-status/Accepted";
import Canceled from "./order-status/Canceled";
import Completed from "./order-status/Completed";
import Delivering from "./order-status/Delivering";
import Pending from "./order-status/Pending";
import Prepared from "./order-status/Prepared";
import Preparing from "./order-status/Preparing";
import OrderDetailTable from "./orderDetailTable";
import Button from "../buttons/Button";

import "../checkout/checkout.css";

const OrderDetail = () => {
  const location = useLocation();
  const { orderID } = useParams();
  const [orderData, setOrderData] = useState(null);
  const [serverMessage, setServerMessage] = useState("");

  // Connect to the Socket.IO server
  // const socket = io.connect(
  //   // "http://localhost:5000/"
  //   // "https://gloriajeanscoffeesbd.com/"
  //   "https://dev.gloriajeanscoffeesbd.com/socket.io/",

  //   {
  //     path: "/socket.io/",
  //     withCredentials: true,
  //     transport: ["websocket", "polling"],
  //     // transports: ["websocket", "xhr-polling"],
  //   }
  // );

  const getSingleOrderDetail = async () => {
    try {
      const response = await api.get(`/user/order/${orderID}`);
      // console.log("Response Data:", response.data);
      setOrderData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setInterval(getSingleOrderDetail, 10 * 1000);
    getSingleOrderDetail();
  }, [serverMessage]);

  // useEffect(() => {
  //   socket.on("orderStatusChange", (data) => {
  //     // console.log('Socket Order status changed:', data);
  //     setServerMessage(data.orderId);
  //     // setOrderStatus(data.orderStatus);
  //   });

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     setServerMessage("");
  //     // socket.off("orderStatusChange");
  //     socket.disconnect();
  //   };
  // }, [socket]);

  const navigate = useNavigate();

  // console.log(location);
  // console.log("orderID:", orderID);
  return (
    <>
      {!orderData ? (
        <Loader />
      ) : (
        // <div
        //   style={{
        //     minHeight: "50vh",
        //     display: "flex",
        //     flexDirection: "column",
        //     gap: "2rem",
        //     justifyContent: "center",
        //     alignItems: "center",
        //   }}
        // >
        //   <h1>The order does not exist</h1>
        //   <Button
        //     onClick={() => navigate(-1)}
        //     buttonText={"Go Back"}
        //     buttonType="contained"
        //   />
        // </div>
        <div className="checkout container">
          <div className="checkout-grid-2 content">
            <div className="col-1">
              <div className="checkout-details-wrapper">
                <OrderDetailTable data={orderData} />
              </div>
            </div>
            <div className="col-2">
              <div className="checkout-image-wrapper">
                {orderData.orderStatus == "Pending" ? (
                  <Accepted />
                ) : // ) : orderData.orderStatus == "Accepted" ? (
                //   <Accepted />
                orderData.orderStatus == "Preparing" ? (
                  <Preparing />
                ) : orderData.orderStatus == "Ready" ? (
                  <Prepared />
                ) : orderData.orderStatus == "Delivering" ? (
                  <Delivering />
                ) : orderData.orderStatus == "Completed" ? (
                  <Completed />
                ) : orderData.orderStatus == "Cancelled" ? (
                  <Pending />
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderDetail;

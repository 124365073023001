import React, { useState, useMemo } from "react";
import Button from "../../buttons/Button";
import Tag from "../cards/tag";
import OutletCarousel from "./outlet-carousel";

import { motion } from "framer-motion";
import {
  animationContainer,
  fadeInLeft,
  fadeInRight,
} from "../../../animations/variants";
import useAnimateOnInView from "../../../animations/useAnimationInView";

const SingleTabContent = ({ outlet }) => {
  const [openingTimes, setOpeningTimes] = useState({
    start: "",
    end: "",
  });

  useMemo(() => {
    const startTime = outlet && outlet.openingHours.start;

    const endTime = outlet && outlet.openingHours.end;

    // const start = new Date(startTime * 1000).toLocaleString("en-US", {
    //   timeZone: "UTC",
    //   hour: "numeric",
    //   minute: "numeric",
    //   hour12: true,
    // });
    // const end = new Date(endTime * 1000).toLocaleString("en-US", {
    //   timeZone: "UTC",
    //   hour: "numeric",
    //   minute: "numeric",
    //   hour12: true,
    // });

    setOpeningTimes({ start: startTime, end: endTime });
  }, [outlet]);
  const outletAnimation = useAnimateOnInView();
  return (
    <motion.div
      ref={outletAnimation.ref}
      animate={outletAnimation.animation}
      initial="hidden"
      variants={animationContainer}
      className="temp-container"
    >
      <div className="single-tab-container">
        <motion.div className="outlet-carousel-container" variants={fadeInLeft}>
          <OutletCarousel
            // outletImage={outletDetails[0].images}
            images={outlet ? outlet.images : []}
          />
        </motion.div>

        <motion.div variants={fadeInRight} className="tab-contents">
          <h3 className="title">
            {outlet !== null ? outlet.name : "no "} Outlet
          </h3>
          <p className="address">
            <img
              src="/images/icons/location.png"
              alt="location"
              style={{ marginRight: "0.5rem" }}
            />
            {outlet !== null ? outlet.address : "no address"}
          </p>
          <p className="phone">
            <img
              src="/images/icons/call.png"
              alt="call"
              style={{ marginRight: "0.5rem" }}
            />
            (+880) {outlet !== null ? outlet.phone : "no phone"}
          </p>
          <div className="divider"></div>
          <p className="opening-hour">
            <span>Opening Hours: </span>
            {outlet !== null
              ? // ` ${openingTimes.start}0 AM - ${openingTimes.end}0 AM`
                openingTimes.start + " - " + openingTimes.end
              : "no opening time"}
          </p>
          <div className="divider"></div>

          <Button
            buttonText={"Get Directions 🡲"}
            buttonType={"redirect"}
            onClick={() => {
              window.open(outlet && outlet.googleMapLink, "_blank");
            }}
          />

          <div className="tags">
            {outlet !== null
              ? outlet.facility.map((tag, index) => (
                  <Tag key={index} tagText={tag} />
                ))
              : "No facilities yet"}
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default SingleTabContent;

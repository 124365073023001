import React from "react";

const Devider = () => {
  return (
    <tr >
      <td colSpan={2}>
        <span className="devider"></span>
      </td>
    </tr>
  );
};

export default Devider;

import React, { useEffect, useState } from "react";

import { motion, useAnimation } from "framer-motion";
import { animationContainer, fadeInUp } from "../../animations/variants";
import { useInView } from "react-intersection-observer";
import { useAppContext } from "../../global/gjcContext";

const ItemCard = ({ item, setModalOpen, setSelectedItem }) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  // Minimize Item Details

  const { outletID } = useAppContext();

  const hours = new Date().getHours();
  const minutes = new Date().getMinutes();
  const seconds = new Date().getSeconds();
  const timeInSec = hours * 3600 + minutes * 60 + seconds;

  // console.log(timeInSec);

  const checkAvailability = () => {
    if (item.availability && item.availability.length > 0) {
      const availability = item.availability.filter(
        (available) => available.outlet == outletID
      );
      if (!availability || availability.length <= 0) {
        return null;
      }
      const start = availability[0].time.start;
      const end = availability[0].time.end;

      if (timeInSec <= start || timeInSec >= end) {
        setButtonDisabled(true);
      }
    }
  };

  useEffect(() => {
    checkAvailability();
  }, []);

  // console.log("Outlet ID::: ", outletID);

  const minifyString = (str) => {
    if (str.length > 70) str = str.substring(0, 70) + "...";

    return str;
  };

  return (
    <div className="item-single" id={item.name} key={item._id}>
      {item.imgUrl ? (
        <img
          className="item-img"
          src={item.imgUrl}
          alt={item.name}
          loading="lazy"
        />
      ) : (
        ""
      )}

      <div className="item-desc">
        <div className="item-top">
          <h3 className="item-name">
            {item.name} <br /> {item.remark ? <span>({item.remark})</span> : ""}{" "}
          </h3>
          {item.badge && item.badge.keto ? (
            <img className="badge" src="/Assets/img/badges/keto.png" />
          ) : (
            ""
          )}
          {item.badge && item.badge.spicy ? (
            <img className="badge" src="/Assets/img/badges/spicy.png" />
          ) : (
            ""
          )}
          {item.badge && item.badge.veg ? (
            <img className="badge" src="/Assets/img/badges/veg.svg" />
          ) : (
            ""
          )}
          {item.badge && item.badge.nuts ? (
            <img className="badge" src="/Assets/img/badges/nuts.svg" />
          ) : (
            ""
          )}
          {item.badge && item.badge.cold ? (
            <img className="badge" src="/Assets/img/badges/cold.svg" />
          ) : (
            ""
          )}
          {item.badge && item.badge.hot ? (
            <img className="badge" src="/Assets/img/badges/hot.svg" />
          ) : (
            ""
          )}
        </div>

        <div className="item-middle">
          {item.details ? <p>{minifyString(item.details)}</p> : ""}
        </div>

        <div className="item-bottom">
          <h4 className="item-price">
            Tk. {item.variation.length && item.variation[0].price}
          </h4>

          {buttonDisabled ? (
            <p className="disabled">Unavailable</p>
          ) : (
            <button
              className="tertiary"
              onClick={() => {
                setModalOpen(true);
                setSelectedItem(item);
              }}
            >
              View Details
            </button>
          )}

          {/* <button
            className="tertiary"
            onClick={() => {
              setModalOpen(true);
              setSelectedItem(item);
            }}
          >
            View Details
          </button> */}
        </div>
      </div>
    </div>
  );
};

// const mapDispatchToProps = {
//   selectItem,
// };

// export default connect(null, mapDispatchToProps)(ItemCard);
export default ItemCard;

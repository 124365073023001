import React from "react";
import "./cart.css";
import "../menu/menu.css";
import { useAppContext } from "../../global/gjcContext";
import CartTable from "./cart-table";

const Cart = () => {
  return (
    <>
      <div className="cart-container container">
        <div className="cart-grid-2 content">
          <CartTable />
          <div className="cart-image-wrapper col-2">
            <img src="/images/cart.svg" alt="cart-image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;


import React, { useEffect } from "react";

import Button from "../buttons/Button";
import CategoryCard from "./cards/category-card";
import OfferCard from "./cards/offer-card";
import HeroSlider from "./HeroSlider/HeroSlider";
import SingleTabContent from "./outlet-section/SingleTabContent";
import TabNavbar from "./outlet-section/tab-navbar";
import CategorySection from "./sections/category-section";
import Hero from "./sections/Hero";
import OfferSection from "./sections/OfferSection";
import Outlets from "./sections/outlets";
import PopulerItemsCarousel from "./sections/populer-items-carousel";
import ServiceSection from "./sections/service-section";
import VideoSection from "./sections/video-section";

// import PopularItemCard from "./cards/popular-item-card";
import "./new-home.css";

const NewHomePage = () => {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `Gloria Jean's Coffees Bangladesh`;
  });

  return (
    <div className="new-home-main">
      <HeroSlider />
      <OfferSection />
      {/* <PopulerItemsCarousel /> */}
      <CategorySection />
      <VideoSection embedId="L7ahUGlB8S0" />
      <ServiceSection />
      <Outlets />
    </div>
  );
};

export default NewHomePage;

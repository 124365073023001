import React from "react";
import { Notifications } from 'react-push-notification';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';

import About from "./components/about/About";
import Login from "./components/auth/Login";
import { PrivateRoute } from "./components/auth/PrivateRoute";
import Register from "./components/auth/Register";
import Cart from "./components/cart/Cart";
import Checkout from "./components/checkout/checkout";
import FoodMenu from "./components/FoodMenu";
import Launch from "./components/Launch";
import Footer from "./components/layouts/Footer";
import HeaderTwo from "./components/layouts/HeaderTwo";
import GJCMenu from "./components/menu/menu";
import SearchResult from "./components/menu/SearchResult";
import NewHomePage from "./components/new-home/new-home";
import AllNotification from "./components/notification/Notification";
import OngoingOrders from "./components/orders/all-order/ongoing-orders";
import OrderDetail from "./components/orders/order-detail";
import FailedPayment from "./components/payment/failed-payment";
import Payment from "./components/payment/payment";
import SuccessPayment from "./components/payment/success-payment";
import EditProfileLayout from "./components/profile/EditProfileLayout";
import Promotions from "./components/Promotions/Promotions";
import Redirect404 from "./components/Redirect404";
import ScrollToTop from "./components/StrollToTop";
import { GjcProvider } from "./global/gjcContext";

import "./App.css";

function App() {
  const notify = () => toast("Wow toast Notification! Wow toast Notification!");
  return (
    <GjcProvider>
      <Router>
      <ToastContainer 
      newestOnTop
      autoClose={4000}
      pauseOnHover
      pauseOnFocusLoss
      />
      <Notifications/>
      <AllNotification/>
        <ScrollToTop />

        <HeaderTwo />
     
        <Routes>
        
          <Route exact path="/about" element={<About />} />
          <Route exact path="/" element={<NewHomePage />} />
          <Route exact path="/menu" element={<GJCMenu />} />
          <Route path="/search" element={<SearchResult />} />
          <Route path="/launch" element={<Launch />} />
          <Route exact path="/promotions" element={<Promotions />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<Redirect404 />} />
          <Route exact path="/cart" element={<Cart />} />
          <Route
            exact
            path="/checkout"
            element={
              <PrivateRoute prevLocation={"/checkout"}>
                <Checkout />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/payment"
            element={
              <PrivateRoute prevLocation={"/payment"}>
                <Payment />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/success-payment"
            element={
              <PrivateRoute prevLocation={"/success-payment"}>
                <SuccessPayment />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/failed-payment"
            element={
              <PrivateRoute prevLocation={"/failed-payment"}>
                <FailedPayment />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/orders"
            element={
              <PrivateRoute prevLocation={"/orders"}>
                <OngoingOrders />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/order-detail/:orderID"
            element={
              <PrivateRoute prevLocation={"/order-detail/:orderID"}>
                <OrderDetail />
              </PrivateRoute>
            }
          ></Route>

          <Route
            exact
            path="/profile"
            element={
              <PrivateRoute prevLocation={"/profile"}>
                <EditProfileLayout />
              </PrivateRoute>
            }
          ></Route>
        </Routes>

        <div>
          <Footer />
        </div>
      </Router>
    </GjcProvider>
  );
}

export default App;

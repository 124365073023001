import React, { useContext, useEffect, useRef, useState } from "react";

import api from "../../api/axios";
import img from "../../call.svg";
import { OurGjcContext } from "../../global/gjcContext";
import { useAppContext } from "../../global/gjcContext";
import Modal from "../layouts/Modal";
import Search from "../menu/Search";
import OrderType from "./OrderType";
import WarningOutletChange from "./WarningOutletChange";

import "./EcommerceHeader.css";

const EcommerceHeader = (countries) => {
  // taking essential context elements from OurGjcContext
  const {
    setMenuData,
    outlets,
    selectedOutlet,
    outletChangeHandler,
    orderType,
    cartItems,
    deliveryLocations,
    selectedDeliveryLocation,
    deliveryLocationChangeHandler,
    setCartItems,
    setcheckoutCartItems,
  } = useContext(OurGjcContext);

  const [countriesExpand, setCountriesExpand] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [outletIndex, setoutletIndex] = useState(0);

  /*************** Get Menu Data *************/

  const getMenuData = async (outletID) => {
    try {
      const response = await api.get("/menu/" + outletID);

      if (response.data) {
        response.data.Food.unshift({
          _id: 1,
          name: "What's New",
          remark: "",
          childs: response.data.newFood,
        });

        response.data.Beverages.unshift({
          _id: 1,
          name: "What's New",
          remark: "",
          childs: response.data.newBeverages,
        });

        response.data.Desserts.unshift({
          _id: 1,
          name: "What's New",
          remark: "",
          childs: response.data.newDesserts,
        });

        setMenuData(response.data);
      }
    } catch (error) {
      console.log("Axios Error: ", error);
    }
  };

  useEffect(() => {
    getMenuData("6226e74f7a33be9c6a848a1d");
    console.log("========console for change outlet===get Data=====");
  }, [outletIndex]);

  /*************** Delivery & Pickup dropdown starts *************/

  /*************** Delivery & Pickup dropdown starts **************/

  const handleOrderType = (outletIndex) => {
    orderType === "delivery"
      ? deliveryLocationChangeHandler(outletIndex)
      : outletChangeHandler(outletIndex);
    setWarningModalOpen(false);
    setCartItems([]);
    setcheckoutCartItems([]);
  };

  let countryRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (countryRef.current && !countryRef.current.contains(event.target)) {
        countriesExpand && setCountriesExpand(false);
      }
    });
  });
  return (
    <>
      <div className="eComNavbar content">
        <div className="leftPart">
          {/* if Needed both pickup and delivery then undo commentout onclick function, in the onclick modal open to select delivery and pickup info*/}
          <button
            className="tertiary highlighted"
            // onClick={() => {
            //   setModalOpen(true);
            // }}
          >
            {orderType == "pickup" ? "Pick-up from" : "Delivery to"} 
            {/* {orderType} */}
          </button>
          {/* Order type Modal */}
          <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            <OrderType onClose={() => setModalOpen(false)} />
          </Modal>
          <Modal
            open={warningModalOpen}
            showButton={false}
            onClose={() => {
              setWarningModalOpen(false);
            }}
          >
            <WarningOutletChange
              handleOrderType={() => {
                handleOrderType(outletIndex);
              }}
              onClose={() => {
                setWarningModalOpen(false);
              }}
            />
          </Modal>

          {/* calling change handler function for onchange */}
          {/* To show selected outlet name in select default value from localstorage "value={selectedOutlet}" is used */}

          {/****** if need delivery function then comment out start ****/}

          <select
            onChange={(e) => {
              setoutletIndex(e.target.value);
              if (orderType === "pickup" && cartItems.length > 0) {
                setWarningModalOpen(true);
              } else {
                outletChangeHandler(e.target.value);
              }
            }}
            value={selectedOutlet}
          >
            {outlets &&
              outlets.map((outlet, i) => (
                <>
                  <option value={i}>{outlet.name}</option>
                </>
              ))}
          </select>
          {/******** if need delivery function then comment out Ends ****/}

          {/******* if need to delivery then unducomment start ****/}
          {/* {orderType == "delivery" ? (
            <select
              onChange={(e) => {
                setoutletIndex(e.target.value);
                if (orderType === "delivery" && cartItems.length > 0) {
                  setWarningModalOpen(true);
                } else {
                  deliveryLocationChangeHandler(e.target.value);
                }
              }}
              value={selectedDeliveryLocation}
            >
              {deliveryLocations &&
                deliveryLocations.map((deliveryLocation, i) => (
                  <>
                    <option value={i}>{deliveryLocation.name}</option>
                  </>
                ))}
            </select>
          ) : (
            <select
              onChange={(e) => {
                setoutletIndex(e.target.value);
                if (orderType === "pickup" && cartItems.length > 0) {
                  setWarningModalOpen(true);
                } else {
                  outletChangeHandler(e.target.value);
                }
              }}
              value={selectedOutlet}
            >
              {outlets &&
                outlets.map((outlet, i) => (
                  <>
                    <option value={i}>{outlet.name}</option>
                  </>
                ))}
            </select>
          )} */}

          {/******** if need to delivery then undu comment  Ends ****/}
        </div>
        <div></div>
        <div className="rightPart" ref={countryRef}>
          {/* <input type="text" placeholder="Search food/drinks" /> */}
          <Search />
          {/* Flags */}
          <a className="nav-call" href="tel:+880929333999">
            <img src={img} />
            <li>(+880) 1929 333 999</li>
          </a>
          <div className="countries">
            <button
              className="countriesButton"
              onClick={() => {
                setCountriesExpand((expand) => !expand);
              }}
            >
              <img src="/Assets/img/countries/Bangladesh.png" alt="BD" />
              BD
            </button>
            <div
              className={
                countriesExpand
                  ? "countriesContainer container expand"
                  : "countriesContainer container"
              }
            >
              <div className="countryFlags">
                {countries.countries.map((flags) => (
                  <div>
                    <img src={flags.img} alt={flags.name} />
                    <a href={flags.website} target="_blank" title={flags.name}>
                      {flags.name}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Flags */}
        </div>
      </div>
    </>
  );
};

export default EcommerceHeader;

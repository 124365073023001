import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

import api from "../../api/axios";
import { OurGjcContext, useAppContext } from "../../global/gjcContext";
import Loader from "../layouts/Loader";

const Register = () => {
  const [name, setName] = useState("");
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isRegisterForm, setIsRegisterForm] = useState(true);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();

  const [navigatePath, setNavigatePath] = useState("/login");

  const navigate = useNavigate();
  const prevLocation = useLocation();

  useEffect(() => {
    if (!prevLocation.state) {
      //
    } else {
      setNavigatePath(prevLocation.state.prevLocation);
    }
  }, [prevLocation]);

  const { setisLoggedIn, setCurrentUser } = useContext(OurGjcContext);

  const userRegisterData = async (e) => {
    e.preventDefault();
    // setLoading(true);

    try {
      const res = await api.post("/register", {
        phone: phone,
      });
      console.log("res ssl url=>>", res);

      if (res.status == 200) {
        setIsRegisterForm(false);
      }
      // Handle successful response here
    } catch (err) {
      console.log("Error:", err);
      setError(err.message);
    }
  };

  const userRegister = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      console.log("check oTP data=>>", "phone=>", phone, "otp", otp);
      // const otpRes = await api.post("/verify-otp", {
      //   number: phone,
      //   otp: otp
      // });
      // console.log("very-opt res=>", otpRes.data)

      const registrationResponse = await api.post("/register/auth", {
        name: name,
        phone: phone,
        email: email,
        otp: otp,
      });

      if (registrationResponse.data.ok === true) {
        // Handle successful registration
        setSuccess(true);
        setError(false);

        setCurrentUser(registrationResponse.data.user);
        setisLoggedIn(true);

        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem(
          "userData",
          JSON.stringify(registrationResponse.data.user)
        );

        // Token works
        if (registrationResponse.data.token) {
          localStorage.setItem("token", registrationResponse.data.token);
          navigate(navigatePath, {});
        } else {
          alert("Token is missing in the response");
        }
        // Token works
      } else {
        setError(
          registrationResponse.data.error ||
            "An error occurred during registration"
        );
        setSuccess(false);
      }
    } catch (err) {
      console.log("Error:", err);
      setError(err.response.data.message || err.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const [counter, setCounter] = useState(120);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <div className="auth">
      <div className="content">
        {isRegisterForm ? (
          <div className="auth-form">
            <h1>Register</h1>
            <p>Fill out the form to create an account.</p>
            <form className="primary" onSubmit={userRegisterData}>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Full Name"
                onChange={(e) => setName(e.target.value)}
              ></input>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Phone Number"
                onChange={(e) => setPhone(e.target.value)}
              ></input>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email (optional)"
                onChange={(e) => setEmail(e.target.value)}
              ></input>

              <button type="submit" className="primary">
                Register
              </button>
            </form>
            <p className="alt-prompt">
              Already have an account?{" "}
              <Link to="/login">
                <span>
                  <b>Log In</b>
                </span>
              </Link>
            </p>
            {error && <p className="error">{error}</p>}
            {success && (
              <p className="error">
                You are successfuly registered!
                {/* <Navigate to={navigatePath} replace={true} /> */}
              </p>
            )}
            {loading && <Loader />}
          </div>
        ) : (
          <div className="auth-form">
            <h1>Verify OTP</h1>
            <p>OTP send in your phone number, please verify</p>
            <form className="primary" onSubmit={userRegister}>
              <input
                type="text"
                id="otp"
                name="otp"
                placeholder="Enter Your OTP"
                onChange={(e) => setOtp(e.target.value)}
              ></input>
              <button type="submit" className="primary">
                Verify
              </button>
              {counter != 0 ? (
                <p className="alt-prompt">
                  An OTP has been sent to your phone number. It will expire in{" "}
                  {counter} seconds.
                </p>
              ) : (
                <p className="alt-prompt">
                  The OTP expired. Please{" "}
                  <a onClick={refreshPage()}>
                    <b>Try Again</b>
                  </a>
                </p>
              )}
            </form>

            {error && <p className="error">{error}</p>}
            {success && (
              <p className="error">
                You are successfuly registered!
                {/* <Navigate to={navigatePath} replace={true} /> */}
              </p>
            )}
            {loading && <Loader />}
          </div>
        )}
      </div>
    </div>
  );
};

export default Register;

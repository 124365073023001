import React, { useState, useEffect } from "react";
import { useAppContext } from "../../global/gjcContext";
import CartActionButtons from "./cart-action-buttons";
import CartItem from "./cart-item";
import CartSubTotal from "./cart-subtotal";
import { subTotalCountIncludeAddons } from "../../utilities/cart/subtotal-count";
import {
  increaseVariation,
  decreaseVariation,
  handleChecked,
  handleUnChecked,
} from "../../utilities/cart/variation-item-count";
import {
  increaseAddons,
  decreaseAddons,
} from "../../utilities/cart/addons-item-count";
import CheckoutTableHeading from "../checkout/checkout-table-heading";
import Modal from "../layouts/Modal";
import { Link } from "react-router-dom";
import CartAddonsItem from "./cart-addons-item";
import CartAlert from "./cart-alert";

const CartTable = () => {
  const {
    cartItems,
    setCartItems,
    SubTotal,
    setSubTotal,
    currentUser,
    singleOutlet,
    outletID,
  } = useAppContext();
  const [expiredItems, setExpiredItems] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  // calculating subtotal of all items in cart including addons
  const subTotalCountWithAddons = () => {
    let total = subTotalCountIncludeAddons(cartItems);

    setSubTotal(total);
  };
  //// incrementing  qty of items in cart variations
  const increaseVariationQty = (item_index, index) => {
    let newarr = increaseVariation(cartItems, item_index, index);
    setCartItems(newarr);
  };
  //// decrementing qty of items in cart variations
  const decreaseVariationQty = (item_index, index) => {
    let newarr = decreaseVariation(cartItems, item_index, index);
    setCartItems(newarr);
  };
  //// incrementing  qty of items in cart addons
  const increaseAddonsQty = (item_index, index) => {
    let newarr = increaseAddons(cartItems, item_index, index);
    setCartItems(newarr);
  };
  //// incrementing  qty of items in cart addons
  const decreaseAddonsQty = (item_index, index) => {
    let newarr = decreaseAddons(cartItems, item_index, index);
    setCartItems(newarr);
  };

  //// chnage checked on click of checkbox
  const handleOnclickChecked = (item_index) => {
    let newarr = handleChecked(cartItems, item_index);
    setCartItems(newarr);
  };
  const handleOnclickUnChecked = (item_index) => {
    let newarr = handleUnChecked(cartItems, item_index);
    setCartItems(newarr);
  };

  useEffect(() => {
    subTotalCountWithAddons();
  }, [cartItems]);

  // useEffect(() => {
  //   // Check availability for each item
  //   cartItems.forEach((item) => {
  //     // Filter Items based on user selected outletID
  //     const ItemByOutlet = item.availability.filter(
  //       (avail) => avail.outlet === outletID
  //     );

  //     //:::::::::::::::time formate Convert Start:::::::::::::::
  //     const [hours, minutes] = ItemByOutlet[0].time.end.split(":");
  //     const endTimeInMinutes = parseInt(hours) * 60 + parseInt(minutes);

  //     // console.log("endTimeInMinutes:::::", endTimeInMinutes, "Org End Time::", ItemByOutlet[0].time.end)

  //     //taking the current time and convert into minutes
  //     const now = new Date();
  //     const currentHours = now.getHours();
  //     const currentMinutes = now.getMinutes();
  //     // Convert the current time into minutes since midnight
  //     const currentTimeInMinutes = currentHours * 60 + currentMinutes;
  //     // console.log("currentTimeInMinutes:::", currentTimeInMinutes)
  //     //:::::::::::::::time formate Convert End:::::::::::::::

  //     if (endTimeInMinutes < currentTimeInMinutes) {
  //       setExpiredItems((prevItems) => [...prevItems, item]);
  //     }
  //   });
  //   console.log("card items:::", cartItems);
  //   console.log("expiredItems instant::", expiredItems);
  // }, [cartItems, outletID]);

  // useEffect(() => {
  //   if (expiredItems.length > 0) {
  //     // setCartItems([])
  //     setModalOpen(true)
  //   }
  //   console.log("expired Items useEffect::", expiredItems)
  // }, [expiredItems])

  // useEffect(() => {
  //   // Check availability for each item
  //   cartItems.forEach(item => {
  //     // Filter Items based on user selected outletID
  //     const EligableItems = item.availability.filter(avail => avail.outlet === outletID);
  //     console.log("EligableItems::::::", EligableItems)

  //     EligableItems.forEach(avail => {
  //       const endTime = parseInt(avail.time.end);
  //       const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds

  //       if (endTime < currentTime) {
  //         setModalOpen(true)
  //       }
  //     });
  //   });
  // }, [cartItems, outletID]);

  // console.log("Curresnt User::::", currentUser)
  // console.log("outletID::::", outletID)
  // console.log("singleOutlet::::", singleOutlet)
  // console.log("card Items::::", cartItems)
  // console.log("expired Items Normal::", expiredItems);

  return (
    <table
      className="cart-item-wrapper col-1"
      style={{ borderBottom: "0.5px solid rgba(171, 148, 109, 0.5)" }}
    >
      <tbody>
        <tr>
          <CheckoutTableHeading title={"Cart"} sub_title="Summary" />
        </tr>
        {cartItems && cartItems.length > 0 ? (
          cartItems &&
          cartItems.map((items, item_index) => (
            <CartItem
              key={item_index}
              items={items}
              item_index={item_index}
              handleOnclickChecked={handleOnclickChecked}
              handleOnclickUnChecked={handleOnclickUnChecked}
              increaseVariationQty={increaseVariationQty}
              increaseAddonsQty={increaseAddonsQty}
              decreaseVariationQty={decreaseVariationQty}
              decreaseAddonsQty={decreaseAddonsQty}
            />
          ))
        ) : (
          <h2>Your Cart is empty</h2>
        )}
        <CartSubTotal SubTotal={SubTotal} />
        <CartActionButtons />
      </tbody>

      {/* <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <CartAlert setModalOpen={setModalOpen} expiredItems={expiredItems} />
      </Modal> */}
    </table>
  );
};

export default CartTable;

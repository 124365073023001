import React from "react";
import Button from "../buttons/Button";
import { Link } from "react-router-dom";

const CancelOrder = ({ orderId, onClose }) => {
  console.log(orderId);
  return (
    <div style={{ textAlign: "", padding: "1rem", maxWidth: "52ch" }}>
      <h3>
        Your Order has been{" "}
        <span style={{ color: "#F57F29", fontWeight: "normal" }}>
          Cancelled!
        </span>{" "}
      </h3>
      <p>
        We are extremely sorry, your order has been cancelled due to
        unavailability of the items. You can order something else or come back
        later. Please call (+880) 01929333999 for more info.
      </p>
      <div className="ordertype-change-action-btn" style={{textAlign:"right"}}>
        <Link to="/menu">
          {" "}
          <Button
            buttonText={"View Menu"}
            buttonType="outlined"
            onClick={onClose}
          />
        </Link>

        <Button onClick={onClose} buttonText={"Close"} buttonType="contained" />
      </div>
    </div>
  );
};

export default CancelOrder;

import React from "react";

const CheckOutTotals = ({ SubTotal }) => {
  return (
    <tr>
      <td>
        <span className="checkout-item-totals"> Sub-Total</span>
      </td>
      <td>
        <span className="checkout-item-totals"> TK.{SubTotal}</span>
      </td>
    </tr>
  );
};

export default CheckOutTotals;

import React from "react";
import ReactDom from "react-dom";

const MODAL_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "var(--bg-secondary)",
  // backgroundColor: "#1a1a1a80",
  // backdropFilter: "blur(20px)",
  boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.4)",
  borderRadius: "16px",
  border: "1px solid rgba(255, 255, 255, 0.15)",
  padding: "clamp(1em, 2.4vw, 2.4em)",
  zIndex: 900000,

  display: "flex",
  flexDirection: "column",
  maxHeight: "80%",
  maxWidth: "90%",
  minWidth: "18rem",
  overflow: "auto",
};

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  zIndex: 900000,
};

function Modal({ open, children, onClose, showButton }) {
  if (!open) return null;

  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} />
      <div style={MODAL_STYLES}>
        {showButton == true && (
          <button className="close-btn" onClick={onClose}></button>
        )}
        {children}
      </div>
    </>,
    document.getElementById("modal")
  );
}

export default Modal;

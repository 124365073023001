import React, { useEffect, useState } from "react";

import { useAppContext } from "../../global/gjcContext";
import { subTotalCountIncludeAddons } from "../../utilities/cart/subtotal-count";
import { totalItemCountFunc } from "../../utilities/checkout/chekout-total-count";
import CheckoutActionButtons from "./checkout-action-buttons";
import CheckoutDeliveryAddress from "./checkout-delivary-address";
import CheckoutHeadings from "./checkout-heading";
import CheckOutItem from "./checkout-item";
import CheckoutLocation from "./checkout-location";
import CheckoutTableHeading from "./checkout-table-heading";
import CheckoutTotalItem from "./checkout-totalItems";
import CheckOutTotals from "./checkout-totals";
import Devider from "./table-divider";

const CheckoutTable = () => {
  const {
    checkoutCartItems,
    SubTotal,
    setSubTotal,
    singleOutlet,
    orderType,
    pickupTime,
    setPickupTime,
  } = useAppContext();

  const [totalItem, setTotalItem] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("digital");

  const selectedPaymentMethod = (method) => {
    setPaymentMethod(method);
  };

  useEffect(() => {
    if (!pickupTime || pickupTime == "" || pickupTime == "undefined") {
      setPickupTime(availableTimeSlots[0]);
    }
    // console.log("SET PICKUP TIME:: ", pickupTime);
  }, [pickupTime]);

  useEffect(() => {
    const totalItemCount = () => {
      let total = totalItemCountFunc(checkoutCartItems);
      setTotalItem(total);
    };
    const subtotalCount = () => {
      const total = subTotalCountIncludeAddons(checkoutCartItems);
      setSubTotal(total);
    };
    totalItemCount();
    subtotalCount();
  }, [checkoutCartItems]);

  //set picup time functionalities from here

  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);

  useEffect(() => {
    const generateTimeSlots = () => {
      const now = new Date();
      const currentHours = now.getHours();
      const currentMinutes = now.getMinutes();

      // Calculate the next valid pickup time (every 30 minutes)
      let nextValidTime =
        Math.ceil((currentHours * 60 + currentMinutes + 30) / 30) * 30;
      // console.log("singleoutlet==>>>", singleOutlet)
      // Convert closing time to the equivalent number of minutes
      const closingTime = singleOutlet.closingTime; 
      console.log("Single outlet::::", singleOutlet)
      const [closingHours, closingMinutes] = closingTime
        ? closingTime.split(":").map(Number)
        : "";
      const closingTimeInMinutes = closingHours * 60 + closingMinutes;

      // Generate time slots every 30 minutes until closing time
      const timeSlots = [];
      while (nextValidTime < closingTimeInMinutes) {
        const nextValidHours = Math.floor(nextValidTime / 60);
        const nextValidMinutes = nextValidTime % 60;

        const displayTime = `${String(
          nextValidHours < 12 ? nextValidHours : nextValidHours - 12
        ).padStart(2, "0")}:${String(nextValidMinutes).padStart(2, "0")} ${
          nextValidHours < 12 ? "am" : "pm"
        }`;

        timeSlots.push(displayTime);
        // Increment by 30 minutes
        nextValidTime += 30;
      }
      // console.log("timeSlots==>", timeSlots)
      setAvailableTimeSlots(timeSlots);
      !pickupTime && setPickupTime(timeSlots[0]);
    };

    generateTimeSlots();
  }, []);

  const handlePickupTimeChange = (event) => {
    setPickupTime(event.target.value);
  };

  // console.log("availableTimeSlots", availableTimeSlots)
  return (
    <form noValidate>
      <table>
        <tbody>
          <tr>
            <CheckoutTableHeading
              title={"Checkout"}
              sub_title={"Order Summary"}
            />
          </tr>
          <Devider />
          <tr>
            <td>
              <h3 className="checkout-item-heading">Items</h3>
            </td>
            <td>
              <h3 className="checkout-item-heading">Quantity</h3>
            </td>
          </tr>
          <Devider />
          {checkoutCartItems.length > 0 ? (
            checkoutCartItems.map((item, item_index) =>
              item.variation.map((variation, variation_index) => (
                <CheckOutItem
                  item={item}
                  variation={variation}
                  variation_index={variation_index}
                />
              ))
            )
          ) : (
            <tr>
              <td colSpan={2}>
                <h3>No items</h3>
              </td>
            </tr>
          )}
          <Devider />
          <CheckoutTotalItem totalitem={totalItem} />
          <CheckOutTotals totalItem={totalItem} SubTotal={SubTotal} />

          <div className="spacer"></div>
          <tr>
            <CheckoutTableHeading sub_title={"Payment Method"} />
          </tr>
          <Devider />
          <tr className="payment-method">
            <td>
              <span>
                <input
                  type="radio"
                  name="payment"
                  value="digital"
                  onClick={() => {
                    selectedPaymentMethod("digital");
                  }}
                  defaultChecked={true}
                />
                <span> Digital</span>
              </span>
              <span className="cash-payment">
                <input
                  type="radio"
                  name="payment"
                  value="cash"
                  onClick={() => {
                    selectedPaymentMethod("cash");
                  }}
                />
                <span> Cash</span>
              </span>
            </td>
          </tr>

          <div className="spacer"></div>

          <tr>
            <CheckoutTableHeading sub_title={"Pickup Information"} />
          </tr>
          <Devider />
          {orderType === "pickup" ? (
            <>
              <tr className="pickup-time">
                <td>Pickup Time</td>
                <td>
                  <select
                    id="pickupTime"
                    value={pickupTime}
                    onChange={handlePickupTimeChange}
                  >
                    {/* <option value="" disabled>
                      -- : --
                    </option> */}
                    {availableTimeSlots.map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                  </select>
                </td>
                {/* Display the selected pickup time */}
                {/* {pickupTime && <p>selected: {pickupTime}</p>} */}
              </tr>
              <tr className="pickup-location">
                <td>Pick-up Location</td>
                <td>{singleOutlet.name}</td>
              </tr>

              <CheckoutLocation />
            </>
          ) : (
            <CheckoutDeliveryAddress />
          )}
          {/* <Devider /> */}
          <CheckoutActionButtons paymentMethod={paymentMethod} />
        </tbody>
      </table>
    </form>
  );
};

export default CheckoutTable;

import React, { useContext, useEffect, useState } from "react";
import { OurGjcContext, useAppContext } from "../../global/gjcContext";
import "./UserProfile.css";
import Modal from "../layouts/Modal";
import AddAddress from "./AddAddress";
import EditProfile from "./EditProfile";
import api from "../../api/axios";
import { Link, Redirect } from "react-router-dom";
import Button, { ImageButton } from "../buttons/Button";
import DeleteAddress from "./deleteAddress";
import EditAddress from "./editAddress";
import EditProfileTwo from "./EditProfileTwo";

const EditProfileLayout = () => {
  const { isLoggedIn, setisLoggedIn, currentUser, setCurrentUser } =
    useContext(OurGjcContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  console.log("current user", currentUser);

  // getUser function updates userdata with updated data
  const getUser = async () => {
    // console.log("working kkk", await AsyncStorage.getItem("token"));
    const response = await api.put("/updateProfile");
    // console.log("response from profile link", response.data.user);
    if (response.data.success === true) {
      setCurrentUser(response.data.user);
      localStorage.setItem("userData", JSON.stringify(response.data.user));
      // console.log('Current User data:', response.data.user);
      // console.log('currentUser from context:', currentUser);
    }
  };
  // console.log("Is Logged in:", isLoggedIn);
  // console.log("User Data:", currentUser);

  const [editMode, setEditMode] = useState(false);
  const [singleAddress, setSingleAddress] = useState({});

  const { setCartItems } = useAppContext();

  const changeEditMode = () => {
    setEditMode(false);
  };
  // console.log('Name:', currentUser.name);

  return (
    <>
      <div className="container">
        <div className="gridify profileHolder content">
          <div className="profileTable">
            <div>
              <table>
                <tr className="justified-between">
                  <h1>Profile</h1>
                  <Link
                    to="/login"
                    className="logoutButton"
                    onClick={() => {
                      localStorage.setItem("isLoggedIn", "false");
                      localStorage.setItem("userData", JSON.stringify({}));
                      setisLoggedIn(false);
                      setCurrentUser({});
                      localStorage.setItem("token", "null");
                      localStorage.setItem("deliveryAddressInfo", JSON.stringify({}));
                      localStorage.setItem("checkoutCartItems", JSON.stringify([]));
                      // localStorage.setItem("cartItems", JSON.stringify([]))
                      // setCartItems([])
                      // localStorage.clear()
                    }}
                  >
                    <button className="secondary">Log Out</button>
                  </Link>
                </tr>
                <tr className="justified-between">
                  <td>Personal Information</td>
                  <td>
                    <ImageButton
                      buttonImgSrc={"/Assets/img/profile/icons/Edit.svg"}
                      buttonType={"redirect-image"}
                      onClick={() => setEditMode(true)}
                    />
                  </td>
                </tr>
              </table>
            </div>

            <div>
              <table className="infoTable">
                <td>
                  <tr>
                    <label>Name</label>
                    <p className="infoFieldValue">{currentUser.name}</p>
                  </tr>
                  <tr>
                    <label>Phone Number</label>
                    <p className="infoFieldValue">{currentUser.phone}</p>
                  </tr>
                  <tr>
                    <label>Email</label>
                    <p className="infoFieldValue">{currentUser.email}</p>
                  </tr>

                  <tr className="justified-between addressTable">
                    <td>
                      <div>
                        <h3>Saved Addresses</h3>
                      </div>
                    </td>
                    <td>
                      <button
                        className="aligned-center addAddressButton"
                        onClick={() => {
                          setModalOpen(true);
                        }}
                      >
                        Add New Address
                        <img
                          src="/Assets/img/profile/icons/Plus.svg"
                          loading="lazy"
                          alt="AddButton"
                        />
                      </button>
                    </td>
                  </tr>
                </td>
              </table>
              <table>
                <tbody className="addressDetails">
                  <Modal
                    open={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    showButton={true}
                  >
                    <DeleteAddress
                      getUser={getUser}
                      singleAddress={singleAddress}
                      onClose={() => {
                        setDeleteModalOpen(false);
                      }}
                    />
                  </Modal>
                  <Modal
                    open={editModalOpen}
                    onClose={() => setEditModalOpen(false)}
                    showButton={false}
                  >
                    <div className="scrollable">
                      <EditAddress
                        setModalOpen={setEditModalOpen}
                        getUser={getUser}
                        singleAddress={singleAddress}
                        onClose={() => setEditModalOpen(false)}
                      />
                    </div>
                  </Modal>
                  {
                    // console.log(currentUser.savedAddress)
                    currentUser &&
                      currentUser.savedAddress &&
                      currentUser.savedAddress.map((currentAddress) => (
                        <tr>
                          <td>
                            <div>
                              <label>{currentAddress.name}</label>
                              <p
                                style={{ fontWeight: "bold", width: "inherit" }}
                              >
                                {currentAddress.house +
                                  ", " +
                                  currentAddress.road +
                                  ", " +
                                  currentAddress.sector}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div
                              style={{ display: "flex", justifyContent: "end" }}
                            >
                              <ImageButton
                                onClick={() => {
                                  setDeleteModalOpen(true);
                                  // console.log("Delete clicked", currentAddress);
                                  setSingleAddress(currentAddress);
                                }}
                                buttonType={"redirect-image"}
                                buttonImgSrc={"/images/icons/Delete.svg"}
                              />
                              <ImageButton
                                onClick={() => {
                                  setEditModalOpen(true);
                                  // console.log("Edit clicked", currentAddress);
                                  setSingleAddress(currentAddress);
                                }}
                                buttonType={"redirect-image"}
                                buttonImgSrc={"/images/icons/Edit.svg"}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                  }
                </tbody>
              </table>
            </div>
          </div>

          {/* Add Address Modal */}
          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            showButton={false}
          >
            <div className="scrollable">
              <AddAddress
                setModalOpen={setModalOpen}
                getUser={getUser}
                onClose={() => setModalOpen(false)}
              />
            </div>
          </Modal>
          {/* Edit profile modal */}
          <Modal
            open={editMode}
            onClose={() => setEditMode(false)}
            showButton={false}
          >
            <div className="scrollable">
              <EditProfileTwo
                changeEditMode={changeEditMode}
                getUser={getUser}
              />
            </div>
          </Modal>

          <div className="profileCoverHolder">
            <img
              src="/Assets/img/profile/img/ProfileUser.svg"
              alt="User Profile Picture"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default EditProfileLayout;

import React, { useEffect, useRef, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import api from "../../api/axios";
import { useAppContext } from "../../global/gjcContext";
import Loader from "../layouts/Loader";
import MetaData from "../layouts/MetaData";
import Modal from "../layouts/Modal";
import MenuPageLoader from "../loader/menuLoader/MenuPageLoader";
import AddOnsDetails from "./AddOnsDetails";
import CategoryNav from "./CategoryNav";
import CategorySection from "./CategorySection";
import ItemDetails from "./ItemDetails";
import Cookies from "js-cookie";

import "./menu.css";
import OrderType from "../layouts/OrderType";

const GJCMenu = () => {
  const [menuType, setMenuType] = useState("Beverages");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [filterselectedItem, setFilterSelectedItem] = useState({});
  const [isVariationSelected, setIsVariationSelected] = useState(false);
  const [modalOpenCookie, setModalOpenCookie] = useState(true);
  const { setCartItems, outletID, menuData, setMenuData } = useAppContext();

  // Declaring state for Menu Data
  // const [menuData, setMenuData] = useState();

  const searchRef = useRef();
  const navigate = useNavigate();

  const menulocation = useLocation();

  useEffect(() => {
    if (!menulocation.state) {
      //
    } else {
      setMenuType(menulocation.state.menu);
    }
  }, [menulocation]);

  //Filtering Menu

  useEffect(() => {
    addFilteredSelectedItem();
    // console.log("selctedItem", selectedItem);
    // selectedItem.variation.map((variation) => console.log(variation.qty));
  }, [selectedItem]);
  // let scrollYPosition = window.scrollY;

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [menuType]);

  const addFilteredSelectedItem = () => {
    let newObject = { ...selectedItem };
    for (const key in newObject) {
      if (Object.hasOwnProperty.call(newObject, key)) {
        if (key === "addOns") {
          const element = newObject[key].filter((items) => items.qty > 0);
          newObject = {
            ...newObject,
            addOns: element,
          };
          // console.log("key:", key, "element:", element);
        }
      }
      if (Object.hasOwnProperty.call(newObject, key)) {
        if (key === "variation") {
          const element = newObject[key].filter(
            (items) => items.qty > 0 || items.qty !== undefined
          );
          newObject = {
            ...newObject,
            variation: element,
          };
          // console.log("key:", key, "element:", element);
        }
      }
    }

    // console.log("filtered data:", newObject);
    setFilterSelectedItem(newObject);
    // setSelectedItem(newObject); need to filter selected items
  };

  function searchMenu(e) {
    const searchTerm = searchRef.current.value;

    const params = { q: searchTerm };

    e.preventDefault();

    if (searchTerm) {
      navigate({
        pathname: "/search",
        search: `?${createSearchParams(params)}`,
      });
    }

    return null;
  }

  // Location select modal shown based on cookie
  useEffect(() => {
    const hasModalCookie = Cookies.get("menu_modal_open");
    console.log("hasModalCookie:::", hasModalCookie);
    if (hasModalCookie === undefined) {
      Cookies.set("menu_modal_open", "yes", { expires: 1 / 8 });
      setModalOpen2(true);
    } else {
      setModalOpen2(false);
    }
  }, []);

  return (
    <>
      <MetaData title={"Menu"} />{" "}
      {!menuData ? (
        <MenuPageLoader />
      ) : (
        <div className="container">
          <Modal open={modalOpen2} onClose={() => setModalOpen2(false)}>
            <div className="scrollable">
              <OrderType onClose={() => setModalOpen2(false)} />
            </div>
          </Modal>

          <div className="sticky-nav  content">
            <div className="menu-head">
              {/* Toggle Menu Type */}
              <div className="menu-toggle">
                <button
                  className={
                    menuType == "Beverages" ? "primary" : "tertiary null"
                  }
                  onClick={() => {
                    setMenuType("Beverages");
                  }}
                >
                  Beverages
                </button>
                <button
                  className={menuType == "Food" ? "primary" : "tertiary null"}
                  onClick={() => {
                    setMenuType("Food");
                  }}
                >
                  Food
                </button>
                <button
                  className={
                    menuType == "Desserts" ? "primary" : "tertiary null"
                  }
                  onClick={() => {
                    setMenuType("Desserts");
                  }}
                >
                  Desserts
                </button>
              </div>

              {/* Search Bar */}
              <form className="searchContainer" onSubmit={searchMenu}>
                <input
                  ref={searchRef}
                  type="text"
                  className="search-right"
                  placeholder="Search food/drinks"
                />
                <div className="search" onClick={searchMenu}></div>
              </form>
            </div>
            <CategoryNav
              categories={
                menuType == "Food"
                  ? menuData.Food
                  : menuType == "Beverages"
                  ? menuData.Beverages
                  : menuData.Desserts
              }
            />
          </div>

          <div className="food-menu content">
            <CategorySection
              categories={
                menuType == "Food"
                  ? menuData.Food
                  : menuType == "Beverages"
                  ? menuData.Beverages
                  : menuData.Desserts
              }
              setModalOpen={setModalOpen}
              setSelectedItem={setSelectedItem}
            />
          </div>

          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            showButton={true}
          >
            <div className="scrollable">
              <ItemDetails
                item={selectedItem}
                setSelectedItem={setSelectedItem}
                setIsVariationSelected={setIsVariationSelected}
              />
              <AddOnsDetails
                item={selectedItem}
                setSelectedItem={setSelectedItem}
                isVariationSelected={isVariationSelected}
              />
            </div>
            <button
              onClick={() => {
                /// TODO: need to add an error message if addons not loaded
                selectedItem.addOns === undefined
                  ? alert("Please Connect your internet")
                  : setCartItems((oldCartItems) => [
                      ...oldCartItems,
                      filterselectedItem,
                    ]);
                setModalOpen(false);
                toast.success("Item(s) successfully added to your cart", {
                  theme: "dark",
                });
              }}
              className={
                isVariationSelected
                  ? "primary toCart"
                  : "primary toCart disabled"
              }
              disabled={isVariationSelected ? false : true}
            >
              Add to Cart
            </button>
          </Modal>
        </div>
      )}
    </>
  );
};

export default GJCMenu;

import React, { useContext, useEffect, useRef } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

const Search = () => {
  const searchRef = useRef();
  const navigate = useNavigate();

  function searchMenu(e) {
    e.preventDefault();
    const searchTerm = searchRef.current.value;
    const params = { q: searchTerm };
    navigate({ pathname: "/search", search: `?${createSearchParams(params)}` });
    return null;
  }

  return (
    <form className="searchContainer" onSubmit={searchMenu}>
      <input
        ref={searchRef}
        className="search-input"
        type="text"
        placeholder="Search food/drinks"
      />
      <div className="search"></div>
    </form>
  );
};

export default Search;

import React from "react";

import style from "./MenuPageLoader.module.css";

const NewsPageLoader = () => {
  return (
    <div className={style.mainContainer}>
      <div className={style.header}>
        <div className={style.innerHeader}></div>
        <div className={style.innerHeader2}></div>
      </div>
      <div className={style.itemGrid}>
        <div className={style.newsCard}>
          <div className={style.img}></div>
          <div className={style.title}>
            <div className={style.left}></div>
            <div className={style.right}></div>
          </div>
          <div className={style.title2}>
            <div className={style.left}></div>
            <div className={style.right}></div>
          </div>
        </div>
        <div className={style.newsCard}>
          <div className={style.img}></div>
          <div className={style.title}>
            <div className={style.left}></div>
            <div className={style.right}></div>
          </div>
          <div className={style.title2}>
            <div className={style.left}></div>
            <div className={style.right}></div>
          </div>
        </div>
        <div className={style.newsCard}>
          <div className={style.img}></div>
          <div className={style.title}>
            <div className={style.left}></div>
            <div className={style.right}></div>
          </div>
          <div className={style.title2}>
            <div className={style.left}></div>
            <div className={style.right}></div>
          </div>
        </div>
        <div className={style.newsCard}>
          <div className={style.img}></div>
          <div className={style.title}>
            <div className={style.left}></div>
            <div className={style.right}></div>
          </div>
          <div className={style.title2}>
            <div className={style.left}></div>
            <div className={style.right}></div>
          </div>
        </div>
        <div className={style.newsCard}>
          <div className={style.img}></div>
          <div className={style.title}>
            <div className={style.left}></div>
            <div className={style.right}></div>
          </div>
          <div className={style.title2}>
            <div className={style.left}></div>
            <div className={style.right}></div>
          </div>
        </div>
        <div className={style.newsCard}>
          <div className={style.img}></div>
          <div className={style.title}>
            <div className={style.left}></div>
            <div className={style.right}></div>
          </div>
          <div className={style.title2}>
            <div className={style.left}></div>
            <div className={style.right}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsPageLoader;

import React from "react";
import CategoryCard from "../cards/category-card";

const CategorySection = () => {
  return (
    <section className="boxed-container category-section-grid">
      <div className="category-right">
        <CategoryCard
          detailsPosition={"right"}
          widthHight={"category-card-width-height"}
          shouldBtn={true}
          imgSrc={"/Assets/img/home/beverage-category-min.jpg"}
          title={"Our world-class"}
          titleSpan={"beverages"}
          details={
            "Experience the rich, bold flavors of premium, hand-crafted coffee imported directly from Australia."
          }
          menu={"Beverages"}
        />
      </div>
      <div className="category-left">
        <CategoryCard
          detailsPosition={"left"}
          widthHight={"category-card-width-height"}
          shouldBtn={true}
          imgSrc={"/Assets/img/home/food-category-min.jpg"}
          title={"Our glorious"}
          titleSpan={"food"}
          details={
            "Treat yourself to delicious and satisfying food options, perfectly paired with our premium coffee."
          }
          menu={"Food"}
        />
      </div>
    </section>
  );
};

export default CategorySection;

import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import api from "../../api/axios";
import { OurGjcContext, useAppContext } from "../../global/gjcContext";
import Loader from "../layouts/Loader";

const Login = () => {
  const [phone, setPhone] = useState("");
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [otp, setOtp] = useState("");
  const [pwd, setPwd] = useState();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigatePath, setNavigatePath] = useState("/profile");

  const { setisLoggedIn, setCurrentUser } = useContext(OurGjcContext);

  const navigate = useNavigate();
  const prevLocation = useLocation();

  useEffect(() => {
    if (!prevLocation.state) {
      //
    } else {
      setNavigatePath(prevLocation.state.prevLocation);
    }
  }, [prevLocation]);

  const userLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Check if phone number is valid
    if (phone.length < 11 || phone.length > 14) {
      setError("Please enter a valid phone number.");
      setLoading(false);
      return;
    }

    try {
      const response = await api.post("/login", {
        phone: phone,
      });
      if (response.status == 200) {
        setIsLoginForm(false);
        setLoading(false);
        setError(false);
      }

      if (response.status != 200) {
        console.log("400 res", response);
        setError(response.data);
        setIsLoginForm(false);
        setLoading(false);
      }
    } catch (err) {
      setError(err.response.data.error);
      setSuccess(false);
      setLoading(false);
    }
  };

  const userLoginAuth = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await api.post("/login/auth", {
        phone: phone,
        otp: otp,
      });
      setCurrentUser(response.data.user);
      setisLoggedIn(true);

      localStorage.setItem("isLoggedIn", true);

      localStorage.setItem("userData", JSON.stringify(response.data.user));

      // Token works
      if (response.data) {
        localStorage.setItem("token", response.data.token);
        navigate(navigatePath, {});
      } else {
        alert(response.data);
      }
      // Token works

      // if (response.data) {

      // let token = response.headers["set-cookie"];
      // token = token[0].split("=");
      // token = token[1];
      // token = token.split(";");
      // token = token[0];

      // await localStorage.setItem("token", token).then(() => {

      // });
      // } else {
      // alert(response.data);
      // }
    } catch (err) {
      console.log(err.message);
      // setError(err.response.data);
      setSuccess(false);
    }
  };
  // importing from context
  const { isLoggedIn, currentUser } = useAppContext();

  // if (isLoggedIn) {
  //     return <Redirect to="/profile"/>;
  // }

  // else{
  // return <Redirect to='/login' />
  // }

  const [counter, setCounter] = useState(120);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <div className="auth">
      <div className="content">
        {isLoginForm ? (
          <div className="auth-form">
            <h1>Log In</h1>
            <p>Enter your phone number & verify to continue. {}</p>
            <form className="primary" onSubmit={userLogin}>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Phone Number"
                onChange={(e) => setPhone(e.target.value)}
              ></input>

              <button type="submit" className="primary">
                Log In
              </button>
            </form>
            <p className="alt-prompt">
              Don’t have an account?{" "}
              <Link to="/register" state={{ prevLocation: navigatePath }}>
                <span>
                  <b>Register</b>
                </span>
              </Link>
            </p>
            {error && <p className="error">{error}</p>} {loading && <Loader />}
          </div>
        ) : (
          <div className="auth-form">
            <h1>Verify Log In</h1>
            <p>Enter your OTP & verify to continue.</p>
            <form className="primary" onSubmit={userLoginAuth}>
              <input
                type="number"
                id="otp"
                name="otp"
                placeholder="Enter Your OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              ></input>

              <button type="submit" className="primary">
                Verify OTP
              </button>
              {counter != 0 ? (
                <p className="alt-prompt">
                  An OTP has been sent to your phone number. It will expire in{" "}
                  {counter} seconds.
                </p>
              ) : (
                <p className="alt-prompt">
                  The OTP expired. Please{" "}
                  <a onClick={refreshPage()}>
                    <b>Try Again</b>
                  </a>
                </p>
              )}
            </form>
            <p className="alt-prompt">
              Don’t have an account?{" "}
              <Link to="/register" state={{ prevLocation: navigatePath }}>
                <span>
                  <b>Register</b>
                </span>
              </Link>
            </p>
            {error && <p className="error">{error}</p>} {loading && <Loader />}
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;

import axios from "axios";

const api = axios.create({
  baseURL: "https://dev.gloriajeanscoffeesbd.com/api/",
  // baseURL: "https://gloriajeanscoffeesbd.com/api/",
  // baseURL: "http://localhost:5000/api",
  // baseURL: "http://66.29.134.125:8000/api",
});

api.interceptors.request.use(async function (config) {
  const token = localStorage.getItem("token");
  config.headers["x-access-token"] = token;
  return config;
});
export default api;

import React from "react";
import { useEffect } from "react";
import { motion } from "framer-motion";
import useAnimateOnInView from "../../animations/useAnimationInView";
import {
  animationContainer,
  fadeInRight,
  fadeInDown,
  // fadeInUp,
} from "../../animations/variants";

import about from "./about.png";
import "./About.css";
import MetaData from "../layouts/MetaData";

/* --------Impoting Animated SVG-------- */
import ProductQuality from "./values/ProductQuality";
import Sustainability from "./values/Sustainability";
import DayPartingService from "./values/DayPartingService";
import Community from "./values/Community";

const About = () => {
  // useEffect(() => {
  //   // Update the document title using the browser API
  //   document.title = `About - Gloria Jean's Coffees Bangladesh`;
  // });

  const aboutAnimation = useAnimateOnInView();
  const logoAnimation = useAnimateOnInView();
  const valuesAnimation = useAnimateOnInView();

  const fadeInUp = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
      },
    },
  };

  return (
    <>
      <div className="pattern-bg">
        <MetaData title={"About"} />
        <motion.div
          ref={aboutAnimation.ref}
          animate={aboutAnimation.animation}
          initial="hidden"
          variants={fadeInDown}
          className="mother-container"
        >
          <motion.div variants={animationContainer} className="main-container">
            <div className="left-container"></div>
            <div className="right-container">
              <motion.h1 variants={fadeInUp} className="about-header">
                <span className="highlighted">About</span> Us
              </motion.h1>
              <motion.h3 variants={fadeInUp}>Gloria Jean’s Coffees</motion.h3>
              <motion.p variants={fadeInUp} className="about-description">
                At Gloria Jean’s Coffees, flavour is in our DNA. From that first
                store that was opened by Gloria Jean Kvetko in Chicago, Illinois
                back in 1979, to the stores in operation across 40 countries
                today, we’ve been on a mission to bring flavour to the world of
                coffee.
              </motion.p>
              <br />
              <motion.h3 variants={fadeInUp}>
                A diverse coffee heritage
              </motion.h3>
              <motion.p variants={fadeInUp} className="about-description">
                We believe that bringing flavour to the world starts with
                finding the best flavour in the world. We source our coffee
                beans from the world’s best coffee growing regions: Africa,
                Asia, and the Americas. From robust roasts to lighter delicate
                blends, we believe that every cup of coffee we serve should
                begin with unmistakable flavour on farms and forests that follow
                sustainable practices. That cup of coffee you love is playing
                its part for our forests, our climate, and the livelihoods and
                welfare of people around the world.
              </motion.p>
              <br />
              <motion.h3 variants={fadeInUp}>
                Flavour is in everything we do
              </motion.h3>
              <motion.p variants={fadeInUp} className="about-description">
                Our commitment to flavour flows through everything we blend,
                from our cosy hot drinks, to our Chillers and over-ice
                beverages. It’s baked into our delicious food items too, and
                perhaps most importantly, into who we are as people. Our kind of
                flavour means character, personality, flair. We know that when
                we bring our own diverse flavours to the table, we create
                something bigger than just coffee—we create a safe space where
                we can all connect and belong.
              </motion.p>
            </div>
          </motion.div>
        </motion.div>
        <motion.section
          ref={logoAnimation.ref}
          animate={logoAnimation.animation}
          initial="hidden"
          variants={animationContainer}
          className="about"
          id="logo-section"
          aria-label="Logo Section"
        >
          <div className="boxed-content">
            <motion.img
              variants={fadeInUp}
              className="logo"
              src="/Assets/img/about/Gloria-Jeans-1979-logo-min.png"
              alt="Gloria Jeans 1979 logo"
            />
            <motion.img
              variants={fadeInUp}
              className="arrow"
              src="/Assets/img/about/logo-arrows.svg"
              alt=""
            />
            <motion.img
              variants={fadeInUp}
              className="logo"
              src="/Assets/img/about/Gloria-Jeans-80s-logo-min.png"
              alt="Gloria Jeans 80s logo"
            />
            <motion.img
              variants={fadeInUp}
              className="arrow"
              src="/Assets/img/about/logo-arrows.svg"
              alt=""
            />
            <motion.img
              variants={fadeInUp}
              className="logo"
              src="/Assets/img/about/Gloria-Jeans-Australia-logo.svg"
              alt="Gloria Jeans Australia logo"
            />
            <motion.img
              variants={fadeInUp}
              className="arrow"
              src="/Assets/img/about/logo-arrows.svg"
              alt=""
            />
            <motion.img
              variants={fadeInUp}
              className="logo long"
              src="/Assets/img/about/Gloria-Jeans-2021-logo.svg"
              alt="Gloria Jeans 2021 logo"
            />
          </div>
        </motion.section>
        <motion.section
          ref={valuesAnimation.ref}
          animate={valuesAnimation.animation}
          initial="hidden"
          variants={animationContainer}
          className="about"
          id="values-section"
        >
          <div className="boxed-content">
            <div className="values-intro">
              <motion.h2 variants={fadeInUp}>
                Our <span>Values</span>
              </motion.h2>
              <motion.p variants={fadeInUp}>
                Ensuring product quality is choosing not to take the easy way.
                It’s not the fast way. It’s the right way, and it creates the
                best coffee possible.
              </motion.p>
            </div>
            <div className="values-grid">
              <motion.div variants={fadeInUp} className="values-card">
                <div className="values-title">
                  <span>THE BASE</span>
                  <h3>Product Quality</h3>
                </div>
                <p>
                  Creating a quality cup of coffee is at the heart of Gloria
                  Jean’s Coffees. It is the reason we go out of our way to
                  source the highest quality arabica beans and take the time to
                  taste and test every batch of beans that we roast.
                </p>
                <img
                  src="/Assets/img/about/product-quality-min.jpg"
                  alt="That’s our reputation in the cup. It’s also our commitment for
                producing high-quality coffee. It’s why we go out of our way to
                source the best quality beans and cup, check each batch of
                coffee roasted."
                  className="value-img"
                />
              </motion.div>

              <motion.div variants={fadeInUp} className="values-card">
                <div className="values-title">
                  <span>THE HEART</span>
                  <h3>Sustainability</h3>
                </div>
                <p>
                  We take our coffee very seriously at Gloria Jean’s Coffees, so
                  not only does it taste great, but we also make sure we buy
                  from suppliers that look after both their workers and the
                  environment. We are focused on promoting sustainable farming
                  and a better outcome for all.
                </p>
                <img
                  src="/Assets/img/about/sustainability-min.jpg"
                  alt="Ensuring Gloria Jean’s is a sustainable business is a high
                priority for us. We’re committed to establishing lasting
                relationships with our farmers, upholding the highest standards
                & methods of making coffee while investing in research and
                product development."
                  className="value-img"
                />
              </motion.div>

              <motion.div variants={fadeInUp} className="values-card">
                <div className="values-title">
                  <span>TOP LAYER</span>
                  <h3>Day Parting Service</h3>
                </div>
                <p>
                  We strive to make our customer's day with our exceptional
                  customer service and coffee. We want every customer that
                  enters our stores to have an experience as glorious as our
                  coffee.
                </p>

                <img
                  src="/Assets/img/about/day-parting.jpg"
                  alt=" Making a customer’s day with our customer service and great
                  tasting coffee. To put a smile on their face and keep them
                  coming back."
                  className="value-img"
                />
              </motion.div>

              <motion.div variants={fadeInUp} className="values-card">
                <div className="values-title">
                  <span>THE FOAM</span>
                  <h3>Community</h3>
                </div>
                <p>
                  Gloria Jean’s Coffees is a global specialty coffee brand that
                  cares about our community. We want our customers to indulge
                  not only in our coffee but in connecting with their family,
                  friends and colleagues in stores. We want every customer to
                  know two lattes are better than one.
                </p>

                <img
                  src="/Assets/img/about/community-min.jpg"
                  alt=" Gloria Jean’s is a global specialty coffee chain that is
                  committed to positively impacting community groups and
                  building livelihoods. Coffee has the ability to make people
                  pause and interact with each other. Human connection is why
                  we’re here, and sometimes we realise it’s as simple as two
                  lattes."
                  className="value-img"
                />
              </motion.div>
            </div>
          </div>
        </motion.section>
      </div>
    </>
  );
};

export default About;

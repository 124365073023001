import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getSeparateTimeandDate } from "../../../utilities/order-details/time-date";

const OrderCard = ({ status, updatedAt, outlet, totalAmount, OrderID }) => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const getTimeNdate = () => {
    const { day, month, year, time } = getSeparateTimeandDate(
      updatedAt !== undefined ? updatedAt : new Date()
    );
    setDate(`${day} ${month} ${year}`);
    setTime(time);
  };

  useEffect(() => {
    getTimeNdate();
    console.log("order date:", updatedAt);
  }, [updatedAt]);
  return (
    <Link to={`/order-detail/${OrderID}`}>
      <div className="order-card">
        <div className="order-card-header">
          <h3>Order ID</h3>
          <h3 className={`${status}`}>{status}</h3>
        </div>
        <div className="order-card-body">
          <span>{date}</span>
          <span>{time}</span>
        </div>
        <div className="order-card-body">
          <span>Outlet: {outlet}</span>
          <span>&#2547; {totalAmount}</span>
        </div>
      </div>
    </Link>
  );
};

export default OrderCard;

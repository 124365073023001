import React, { useState, useEffect } from "react";
import { useAppContext } from "../../global/gjcContext";
import "./cart.css";
import { Link } from "react-router-dom";
import CartAddonsItem from "./cart-addons-item";

const CartAlert = ({ expiredItems, setModalOpen }) => {
  const {
    cartItems,
    setCartItems,
    SubTotal,
    setSubTotal,
    currentUser,
    singleOutlet,
    outletID,
  } = useAppContext();

  return (
    <div className="unableBox">
      <h3>
        Some Items <span className="highlighted"> Aren't Available </span>{" "}
        Anymore
      </h3>
      {expiredItems &&
        expiredItems.map((item, index) => (
          <>
            <p key={index}>• {item.name}</p>
          </>
        ))}
      <span className="note">
        {" "}
        You can choose some other items from the{" "}
        <span className="highlighted"> Menu</span> or Proceed to{" "}
        <span className="highlighted">Cart.</span>
        {/* <span className="highlighted">NB:</span> Availability of those foods are
        between (10am - 2pm, everyday) */}
      </span>
      <div className="Boxbuttom">
        <Link to="/menu">
          <button className="secondary">View Menu</button>
        </Link>

        <button onClick={() => setModalOpen(false)} className="primary">
          Proceed to Cart
        </button>
      </div>
    </div>
  );
};

export default CartAlert;

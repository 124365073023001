import React, { useEffect, useState, useRef } from "react";
import "./menu.css";
import CategorySection from "./CategorySection";
import ItemCard from "./ItemCard";
import Modal from "../layouts/Modal";
import ItemDetails from "./ItemDetails";
import AddOnsDetails from "./AddOnsDetails";
import Loader from "../layouts/Loader";
import { useAppContext } from "../../global/gjcContext";

import { useSearchParams } from "react-router-dom";

const SearchResult = () => {
  const searchRef = useRef();
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchResults, setSearchResults] = useState([]);
  const searchTerm = searchParams.get("q");

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [filterselectedItem, setFilterSelectedItem] = useState({});
  const [isVariationSelected, setIsVariationSelected] = useState(false);

  const { setCartItems, menuData } = useAppContext();

  // console.log("RELOAD!");

  useEffect(() => {
    if (menuData && searchTerm) {
      // console.log("Search Term: ", searchTerm);
      searchMenu();
    }
  }, [searchTerm, menuData]);

  // useEffect(() => {
  //   console.log("SEARCH RESULTS:: ", searchResults);
  //   console.log("Full Menu:", menuData);
  // }, [searchResults]);

  //Filtering Menu

  useEffect(() => {
    addFilteredSelectedItem();
    // console.log("selctedItem", selectedItem);
    // selectedItem.variation.map((variation) => console.log(variation.qty));
  }, [selectedItem]);

  const addFilteredSelectedItem = () => {
    let newObject = { ...selectedItem };
    for (const key in newObject) {
      if (Object.hasOwnProperty.call(newObject, key)) {
        if (key === "addOns") {
          const element = newObject[key].filter((items) => items.qty > 0);
          newObject = {
            ...newObject,
            addOns: element,
          };
          // console.log("key:", key, "element:", element);
        }
      }
      if (Object.hasOwnProperty.call(newObject, key)) {
        if (key === "variation") {
          const element = newObject[key].filter(
            (items) => items.qty > 0 || items.qty !== undefined
          );
          newObject = {
            ...newObject,
            variation: element,
          };
          // console.log("key:", key, "element:", element);
        }
      }
    }

    // console.log("filtered data:", newObject);
    setFilterSelectedItem(newObject);
    // setSelectedItem(newObject); need to filter selected items
  };

  function newSearch(e) {
    e.preventDefault();

    const params = { q: searchRef.current.value };

    setSearchParams(params);
  }

  function searchMenu() {
    let food = menuData.Food;
    let drinks = menuData.Beverages;
    let desserts = menuData.Desserts;

    let searchFood = rSearch(food, searchTerm);
    let searchBeverages = rSearch(drinks, searchTerm);
    let searchDesserts = rSearch(desserts, searchTerm);

    setSearchResults([...searchFood, ...searchBeverages, ...searchDesserts]);

    return null;
  }

  const rSearch = (arr, searchTerm) => {
    // let result = [];
    // arr.map((cat) => {
    //   let x = cat.childs.filter((single) =>
    //     single.name.toLowerCase().includes(searchTerm.toLowerCase())
    //   );
    //   result.push(...x);
    // });

    let result = [];

    arr.forEach((cat) => {
      if (cat.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        result.push(...cat.childs);
      } else {
        cat.childs.forEach((item) => {
          if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            result.push(item);
          }
        });
      }
    });

    // let data = arr;

    // let result = data.filter((cat) => {
    //   console.log("Children::", cat.childs);
    //   return cat.name.toLowerCase().includes(searchTerm.toLowerCase());
    // });

    // if (result.length <= 0) {
    //   data.forEach((cat) => {
    //     let x = cat.childs.filter((single) =>
    //       single.name.toLowerCase().includes(searchTerm.toLowerCase())
    //     );

    //     let newCat = cat;

    //     newCat.childs = x;

    //     if (newCat.childs.length > 0) {
    //       result.push(newCat);
    //       console.log("Children::", newCat.childs);
    //     }
    //   });
    // }

    // console.log("from search page", result);

    return result;
  };

  // const searchResults = rSearch(menuData.Food, searchTerm);

  return (
    <div className="container">
      <div className="sticky-nav content">
        <div className="menu-head">
          <form className="searchContainer" onSubmit={newSearch}>
            <input
              ref={searchRef}
              type="text"
              className="search-right"
              placeholder="Search food/drinks"
              defaultValue={searchTerm}
            />
            <div className="search" onClick={newSearch}></div>
          </form>
        </div>
      </div>

      <div className="food-menu content">
        {!searchResults ? (
          <Loader />
        ) : (
          // <CategorySection
          //   categories={searchResults}
          //   setModalOpen={setModalOpen}
          //   setSelectedItem={setSelectedItem}
          // />
          <>
            <section className="category-section">
              <h2 id={"search-title"} className="cat-name">
                Search Results
              </h2>

              <div className="item-grid">
                {searchResults.map((item) => (
                  <ItemCard
                    item={item}
                    setModalOpen={setModalOpen}
                    setSelectedItem={setSelectedItem}
                  />
                ))}
              </div>
            </section>
            <section
              style={{ height: "12rem" }}
              className="category-section"
            ></section>
          </>
        )}
      </div>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        showButton={true}
      >
        <div className="scrollable">
          <ItemDetails
            item={selectedItem}
            setSelectedItem={setSelectedItem}
            setIsVariationSelected={setIsVariationSelected}
          />
          <AddOnsDetails
            item={selectedItem}
            setSelectedItem={setSelectedItem}
            isVariationSelected={isVariationSelected}
          />
        </div>
        <button
          onClick={() => {
            /// TODO: need to add an error message if addons not loaded
            selectedItem.addOns === undefined
              ? alert("Please Connect your internet")
              : setCartItems((oldCartItems) => [
                  ...oldCartItems,
                  filterselectedItem,
                ]);
            setModalOpen(false);
          }}
          className={
            isVariationSelected ? "primary toCart" : "primary toCart disabled"
          }
          disabled={isVariationSelected ? false : true}
        >
          Add to Cart
        </button>
      </Modal>
    </div>
  );
};

export default SearchResult;

import React, { useEffect, useState } from "react";
import Loader from "../layouts/Loader";
import api from "../../api/axios";
const AddOnsDetails = ({ item, setSelectedItem, isVariationSelected }) => {
  const [loading, setLoading] = useState(false);
  // console.log("loading:", loading);
  useEffect(() => {
    const getaddOnsData = async () => {
      try {
        const response = await api.get("/addon/" + item._id);
        if (response.data) {
          setLoading(true);
        }
        response.data.map((addOn) => (addOn.qty = 0));
        // setaddOnsData(response.data);
        setSelectedItem({
          ...item,
          addOns: response.data,
        });
      } catch (error) {
        if (error) {
          setLoading(false);
        }
        console.log("Axios Error: ", error);
      }
    };

    if (!isVariationSelected) {
      getaddOnsData();
    }
  }, [isVariationSelected]);

  //Function for Counter Minus
  function incrementQty(addOnID) {
    let updateAddOns = item.addOns;
    let i = updateAddOns.findIndex((addOn) => addOn._id === addOnID);

    if (updateAddOns[i].qty) {
      updateAddOns[i].qty += 1;
    } else {
      updateAddOns[i].qty = 1;
    }

    setSelectedItem({
      ...item,
      addOns: updateAddOns,
    });
  }

  //Function for Counter Minus
  function decrementQty(addOnID) {
    let updateAddOns = item.addOns;
    let i = updateAddOns.findIndex((addOn) => addOn._id === addOnID);

    if (updateAddOns[i].qty) {
      updateAddOns[i].qty -= 1;
    } else {
      updateAddOns[i].qty = 0;
    }

    // let addOn = addOns[i];
    setSelectedItem({
      ...item,
      addOns: updateAddOns,
    });
  }

  //Function for Checkbox check
  function setQuantyOne(addOnID) {
    let updateAddOns = item.addOns;
    let i = updateAddOns.findIndex((addOn) => addOn._id === addOnID);

    updateAddOns[i].qty = 1;

    setSelectedItem({
      ...item,
      addOns: updateAddOns,
    });
  }

  //Function for Checkbox uncheck
  function setQuantyZero(addOnID) {
    let updateAddOns = item.addOns;
    let i = updateAddOns.findIndex((addOn) => addOn._id === addOnID);

    updateAddOns[i].qty = 0;

    setSelectedItem({
      ...item,
      addOns: updateAddOns,
    });
  }

  const renderAddOn = (addOn) => {
    return (
      <>
        <div className="start">
          <input
            type="checkbox"
            className="check"
            checked={addOn.qty > 0 ? true : false}
            disabled={isVariationSelected ? false : true}
            onClick={() => {
              if (addOn.qty > 0) {
                setQuantyZero(addOn._id);
              } else {
                setQuantyOne(addOn._id);
              }
            }}
          />
          <h4
            className={
              isVariationSelected ? "price-label" : "price-label disabled"
            }
          >
            {addOn.name}{" "}
            {addOn.remark && (
              <span className="remark">{" (" + addOn.remark + ")"}</span>
            )}
          </h4>
        </div>
        <div className="middle">
          <h5
            className={
              isVariationSelected ? "item-price" : "item-price disabled"
            }
          >
            Tk. {addOn.price}
          </h5>
        </div>
        <div className="end">
          <button
            className={isVariationSelected ? "minus" : "minus disabled"}
            onClick={() => {
              decrementQty(addOn._id);
            }}>
            -
          </button>
          <input
            type="text"
            className={isVariationSelected ? "quantity" : "quantity disabled"}
            value={addOn.qty}></input>
          <button
            className={isVariationSelected ? "plus" : "plus disabled"}
            disabled={isVariationSelected ? false : true}
            onClick={() => {
              incrementQty(addOn._id);
            }}>
            +
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      {!item.addOns ? (
        <Loader />
      ) : (
        <>
          {item.addOns.length ? (
            <>
              <hr className="divider" />

              <section className="addons">
                <h2 className="modal-heading" id="t1">
                  Add-ons
                </h2>
                <div className="item-table">{item.addOns.map(renderAddOn)}</div>
              </section>
            </>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default AddOnsDetails;
